import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAdditionalFiles, getProgramData } from "../../services/api/robot";
import { novEyeVideos } from "../../libs/utils";
import * as FetchingHelper from "../../redux/fetchingHelper";

const sliceName = "videos";

const videoSlice = createSlice({
  name: sliceName,
  initialState: {
    ...FetchingHelper.getInitialState(),
    hevcSupport: true,
    showDiagnosticView: false,
    diagnosticViewFilter: {
      "Show Reports": false,
      "Show Welds": false,
      "Show Video Files": true,
      "Show Log Files": true,
    },
    dailyEventView: { welds: true, matchedFiles: false, unmatchedFiles: true, additionalFiles: true },
    videos: null,
    additionalFiles: [],
  },

  reducers: {
    setHevcSupport: (state, action) => ({
      ...state,
      hevcSupport: action.payload,
    }),

    setDiagnosticView: (state, action) => ({
      ...state,
      showDiagnosticView: action.payload,
    }),

    setDiagnosticViewFilter: (state, action) => ({
      ...state,
      diagnosticViewFilter: action.payload,
    }),

    setFilterDayView: (state, action) => ({
      ...state,
      dailyEventView: { ...state.dailyEventView, ...action.payload },
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(...FetchingHelper.createBasicPendingCase(getRobotVideos))
      .addCase(
        ...FetchingHelper.createBasicFulfilledCase(getRobotVideos, "videos"),
      )
      .addCase(...FetchingHelper.createBasicRejectedCase(getRobotVideos))
      .addCase(...FetchingHelper.createBasicPendingCase(additionalFilesAction))
      .addCase(
        ...FetchingHelper.createBasicFulfilledCase(additionalFilesAction, "additionalFiles"),
      )
      .addCase(...FetchingHelper.createBasicRejectedCase(additionalFilesAction));
  },
});

export const {
  setHevcSupport,
  setDiagnosticView,
  setDiagnosticViewFilter,
  setFilterDayView,
} = videoSlice.actions;
export const videoSliceKey = videoSlice.name;
export default videoSlice.reducer;
export const getRobotVideos = createAsyncThunk<
  any,
  { id: string; start?: string; end?: string }
>(`${sliceName}/fetchListRequest`, async (query) => {
  const response = await getProgramData(query.id, { key: "videos", ...query });
  return { data: novEyeVideos(response?.data) };
});

export const additionalFilesAction = createAsyncThunk<
  any,
  { id: string; start?: string; end?: string }
>(`${sliceName}/additionalFilesAction`, async (query) => {
  return getAdditionalFiles(query.id, query);
});
