import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ArrowRightOutlined, MailOutlined, PhoneOutlined, WarningOutlined } from "@ant-design/icons";
import { Col, Row } from 'antd';

import {
  CUSTOMER_SUPPORT_EMAIL,
  CUSTOMER_SUPPORT_PHONE,
  CUSTOMER_SUPPORT_PHONE_2,
  CUSTOMER_SUPPORT_PHONE_3,
  CUSTOMER_SUPPORT_PHONE_FORMATTED,
  CUSTOMER_SUPPORT_PHONE_FORMATTED_2,
  CUSTOMER_SUPPORT_PHONE_FORMATTED_3,
  REGEX_EMAIL
} from "../../libs/constants";
import { getRobotDataShareConsent, putRobotDataShareConsent } from "./robotSlice";

export default function RobotSupport(props) {
  const { robotName } = useParams();
  const robotId =
    robotName && robotName.match(/(\d+)/) && robotName.match(/(\d+)/)[0];
  const dispatch = useDispatch();
  // FIXME: the api currently returns an array despite requesting a single robot data
  const consentData = useSelector((state) => state.robot.weldTestConsent)?.[0];
  const savedName = consentData?.name || undefined;
  const savedEmail = consentData?.email || undefined;
  const savedAgree = consentData?.agreed || undefined;
  const consentReceived = savedName || savedEmail || savedAgree;
  const link = consentData?.link;

  useEffect(() => {
    dispatch(getRobotDataShareConsent(robotId))
  }, [dispatch, robotId]);

  return (
    <>
      <Row className="support-container">
        <Col className="support-container-half" xs={23} sm={23} md={11} lg={11} xl={11} >
          <div className="support-container-card">
            <h1>Contact Information</h1>

            <div className="support-block">
              <div>
                <h2>Novarc Technologies</h2>
              </div>
              <div className="contact-info">
                <div>
                  <PhoneOutlined rotate={90} />
                  <a href={"tel:" + CUSTOMER_SUPPORT_PHONE}>{CUSTOMER_SUPPORT_PHONE_FORMATTED}</a>
                </div>
                <div>
                  <div style={{ padding: '10px  0' }}>
                    <b>Toll Free</b>
                  </div>
                  <PhoneOutlined rotate={90} />
                  North America: <a href={"tel:" + CUSTOMER_SUPPORT_PHONE_2}>{CUSTOMER_SUPPORT_PHONE_FORMATTED_2}</a>
                </div>
                <div>
                  <PhoneOutlined rotate={90} />
                  Austrailia: <a href={"tel:" + CUSTOMER_SUPPORT_PHONE_3}>{CUSTOMER_SUPPORT_PHONE_FORMATTED_3}</a>
                </div>
                <div>
                  <MailOutlined />
                  <a href={"mailto:" + CUSTOMER_SUPPORT_EMAIL}>{CUSTOMER_SUPPORT_EMAIL}</a>
                </div>
              </div>
            </div>
          </div>
        </Col>

        <Col className="support-container-half" xs={23} sm={23} md={11} lg={11} xl={11} >
          <div className="support-container-card">
            <h1>Form</h1>
            <div className="support-block">
              <h2>Consent Form</h2>
              <form className="consent-form" onSubmit={function (ev) {
                ev.preventDefault();
                const name = ev.target[0].value;
                const email = ev.target[1].value;
                const agreed = ev.target[2].checked;
                const validEmail = REGEX_EMAIL.test(email);

                if (!name || !validEmail || !agreed) {
                  alert("Please fill in the consent form with a valid email and check the box to agree with the terms.");
                  return;
                }

                ev.target[0].setAttribute("disabled", "");
                ev.target[1].setAttribute("disabled", "");
                ev.target[2].setAttribute("disabled", "");
                ev.target[3].setAttribute("disabled", "");
                ev.target[3].setAttribute("value", "Sending...");
                // short delay to provide UX feedback on submission
                // if API call successful, form will refresh afterward
                setTimeout(
                  () =>
                    dispatch(
                      putRobotDataShareConsent({
                        robot_id: robotId,
                        payload: {
                          name: name,
                          email: email,
                          agreed: agreed,
                        },
                      })
                    ),
                  1000
                );
              }}>
                <p>Please read and sign the form before submitting your consent!</p>
                <label className="form-item">
                  <span>Name</span>
                  <input type="text"
                    placeholder="e.g. John Smith"
                    disabled={consentReceived}
                    value={savedName}
                    required
                  />
                </label>
                <label className="form-item">
                  <span>Email</span>
                  <input
                    type="email"
                    pattern={REGEX_EMAIL}
                    placeholder="e.g. jsmith@gmail.com"
                    disabled={consentReceived}
                    value={savedEmail}
                    required
                  />
                </label>
                <div className="form-item">
                  <span>Form</span>
                  {link
                    ? <a href={link} target="_blank" rel="noreferrer">Access to Weld Tests and Analysis Consent Form <ArrowRightOutlined /></a>
                    : <span style={{ color: "#f5222d" }}>Link unavailable - Please contact Admin <WarningOutlined /></span>
                  }
                </div>
                <label className="form-item form-item-checkbox">
                  <input type="checkbox" defaultChecked={savedAgree} disabled={consentReceived} />
                  <span className="agree-text">I Agree With the Terms on the Document Above</span>
                </label>
                <label className="form-submit">
                  <p className="warn"><span style={{ fontWeight: "bold" }}>Please Note: </span>Fields are not editable after submission!</p>
                  <input type="submit" value={consentReceived ? "Submitted" : "Submit"} disabled={consentReceived || !link} />
                </label>
              </form>
            </div>
          </div>
        </Col>
      </Row >
    </>
  )
}
