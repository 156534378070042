import { List } from "antd";
import {
  AttachmentIcon,
  PreviewIcon,
} from "../../components/icons/NovarcIcons";
import { DownloadOutlined } from "@ant-design/icons";
import React from "react";
import { S3File } from "../robot/robotSearchUtils";

interface Props {
  robotId;
  reportFiles: S3File[];
  handleModalPreviewOpen;
  handleUsageUpdate;
}

export function GroupedDailyReports(props: Props) {
  const { robotId, reportFiles, handleModalPreviewOpen, handleUsageUpdate } =
    props;

  return (
    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
      <div
        style={{
          display: "flex",
          alignItems: "start",
          justifyContent: "start",
        }}
      >
        <div
          style={{
            color: "#262626",
            fontSize: 18,
            fontFamily: "Roboto",
            fontWeight: 600,
            wordWrap: "break-word",
          }}
        >
          {/*<FilePdfOutlined /> Daily Report*/}
        </div>
      </div>
      <div
        style={{ display: "flex", alignItems: "start", justifyContent: "end" }}
      >
        <List>
          {reportFiles.map((item, i) => {
            const reportDate = item.fileName?.match(/(\d{4}-\d{2}-\d{2})/);
            return (
              <List.Item
                className={"weld-files-daily-report-item"}
                key={i}
                style={{ display: "flex", justifyContent: "right" }}
                actions={[
                  <span
                    style={{
                      color: "#1890ff",
                      fontWeight: 600,
                      border: "1px solid #F0F0F0",
                      padding: "7px 8px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleModalPreviewOpen(item)}
                  >
                    <PreviewIcon
                      style={{
                        color: "#1890ff",
                        cursor: "pointer",
                      }}
                    />{" "}
                    Preview
                  </span>,
                  <span>
                    <a
                      style={{
                        color: "#fff",
                        backgroundColor: "#3076B8",
                        padding: "7px 8px",
                      }}
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <DownloadOutlined
                        style={{
                          color: "#fff",
                          paddingRight: 4,
                          cursor: "pointer",
                        }}
                        onClick={() => handleUsageUpdate(robotId, item)}
                      />
                      Download
                    </a>
                  </span>,
                ]}
              >
                <div>
                  <AttachmentIcon />
                  Daily Report {reportDate ? reportDate[0] : ""}
                </div>
              </List.Item>
            );
          })}
        </List>
      </div>
    </div>
  );
}
