import axios from "axios";

import {
  API_ADMIN_GROUPS,
  API_ADMIN_USERS,
  API_ADMIN_ROLES,
  API_ADMIN_ROBOTS,
  API_USERS_ROBOTS,
  API_ADMIN_ROOT,
} from "../../libs/constants";

import { getToken } from "../../libs/auth";
import { normalizeRobotId } from "../../libs/utils";

// ##############################
// Admin
// ##############################
export async function getGroups() {
  const url = `${API_ADMIN_GROUPS}`;

  const options = {
    method: "get",
    headers: { Authorization: `bearer ${getToken()}` },
    url,
  };

  try {
    const { data, status } = await axios(options);

    if (status === 200) {
      return data;
    } else {
      throw new Error(status);
    }
  } catch (err) {
    throw err;
  }
}

export async function newGroup(payload) {
  const url = `${API_ADMIN_GROUPS}`;

  const options = {
    method: "post",
    headers: { Authorization: `bearer ${getToken()}` },
    url,
    data: { ...payload },
  };

  try {
    const { data, status } = await axios(options);

    if (status === 200) {
      return data;
    } else {
      throw new Error(status);
    }
  } catch (err) {
    throw err;
  }
}

export async function editGroupResource(id, payload) {
  const url = `${API_ADMIN_GROUPS}/${id}/resource`;

  const options = {
    method: "put",
    headers: { Authorization: `bearer ${getToken()}` },
    url,
    data: { ...payload },
  };

  try {
    const { data, status } = await axios(options);

    if (status === 200) {
      return data;
    } else {
      throw new Error(status);
    }
  } catch (err) {
    throw err;
  }
}

export async function newTier(params) {
  const url = `${API_USERS_ROBOTS}/${params}/bandwidth`;

  const options = {
    method: "post",
    headers: { Authorization: `bearer ${getToken()}` },
    url,
  };
  try {
    const { data, status } = await axios(options);

    if (status === 200) {
      return data;
    } else {
      throw new Error(status);
    }
  } catch (err) {
    throw err;
  }
}

export async function updateUsage(params, payload) {
  const url = `${API_USERS_ROBOTS}/${params}/bandwidth`;

  const options = {
    method: "put",
    headers: { Authorization: `bearer ${getToken()}` },
    url,
    data: payload,
  };
  try {
    const { data, status } = await axios(options);

    if (status === 200) {
      return data;
    } else {
      throw new Error(status);
    }
  } catch (err) {
    throw err;
  }
}

export async function getRobots() {
  const url = `${API_ADMIN_ROBOTS}`;

  const options = {
    method: "get",
    headers: { Authorization: `bearer ${getToken()}` },
    url,
  };

  try {
    const { data, status } = await axios(options);

    if (status === 200) {
      // temp monkeypatching - /robots api sends 1805 in the name field instead of the ID field
      const swr1805 = data.find((e) => e.name === "SWR-1805");
      if (swr1805) swr1805.name = "SWR-185";

      return data;
    } else {
      throw new Error(status);
    }
  } catch (err) {
    throw err;
  }
}

export async function getNotes() {
  const url = `${API_ADMIN_ROOT}/notes`;

  const options = {
    method: "get",
    headers: { Authorization: `bearer ${getToken()}` },
    url,
  };

  const { data, status } = await axios(options);

  if (status === 200) {
    return data;
  } else {
    throw new Error(status);
  }
}

export async function postNotes(body) {
  const url = `${API_ADMIN_ROOT}/notes`;

  const options = {
    method: "post",
    headers: { Authorization: `bearer ${getToken()}` },
    url,
    data:body
  };

  const { data, status } = await axios(options);

  if (status === 200) {
    return data;
  } else {
    throw new Error(status);
  }
}

export async function getUsers() {
  const url = `${API_ADMIN_USERS}`;

  const options = {
    method: "get",
    headers: { Authorization: `bearer ${getToken()}` },
    url,
  };

  try {
    const { data, status } = await axios(options);

    if (status === 200) {
      return data;
    } else {
      throw new Error(status);
    }
  } catch (err) {
    throw err;
  }
}

// no current usages
export async function getRoles() {
  const url = `${API_ADMIN_ROLES}`;

  const options = {
    method: "get",
    headers: { Authorization: `bearer ${getToken()}` },
    url,
  };

  try {
    const { data, status } = await axios(options);

    if (status === 200) {
      return data;
    } else {
      throw new Error(status);
    }
  } catch (err) {
    throw err;
  }
}

export async function editRoleResource(id, payload) {
  const url = `${API_ADMIN_USERS}/role`;

  console.log("sr", url, payload);

  const options = {
    method: "put",
    headers: { Authorization: `bearer ${getToken()}` },
    url,
    data: { ...payload },
  };

  try {
    const { data, status } = await axios(options);

    if (status === 200) {
      return data;
    } else {
      throw new Error(status);
    }
  } catch (err) {
    throw err;
  }
}

export async function createConsentForm(id, payload) {
  const idNum = normalizeRobotId(id);

  const { link } = payload;
  const url = `${API_ADMIN_ROBOTS}/${idNum}/weld_test_consents`;

  const options = {
    method: "post",
    headers: { Authorization: `bearer ${getToken()}` },
    url,
    data: { link: link },
  };

  try {
    const { data, status } = await axios(options);

    if (status === 200) {
      return data;
    } else {
      throw new Error(status);
    }
  } catch (err) {
    throw err;
  }
}
export async function getConsentForm(id) {
  const idNum = normalizeRobotId(id);

  const url = `${API_ADMIN_ROBOTS}/${idNum}/weld_test_consents`;

  const options = {
    method: "get",
    headers: { Authorization: `bearer ${getToken()}` },
    url,
  };

  try {
    const { data, status } = await axios(options);

    if (status === 200) {
      return data;
    } else {
      throw new Error(status);
    }
  } catch (err) {
    throw err;
  }
}

export async function resendEmail(id) {
  const url = `${API_ADMIN_USERS}/${id}`;

  const options = {
    method: "get",
    headers: { Authorization: `bearer ${getToken()}` },
    url,
  };

  try {
    const { data, status } = await axios(options);

    if (status === 200) {
      return data;
    } else {
      throw new Error(status);
    }
  } catch (err) {
    throw err;
  }
}

export async function deleteUser(id) {
  const url = `${API_ADMIN_USERS}/${id}`;

  const options = {
    method: "delete",
    headers: { Authorization: `bearer ${getToken()}` },
    url,
  };

  try {
    const { data, status } = await axios(options);

    if (status === 200) {
      return data;
    } else {
      throw new Error(status);
    }
  } catch (err) {
    throw err;
  }
}
