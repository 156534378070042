import { WeldRunWithFiles } from "../robot/videosAndReports/groupWeldRun";
import {
  formatDateAsFullText,
  formatDateAsHHmmss,
} from "../../libs/utilsTyped";
import { Table, Tooltip } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { PreviewIcon } from "../../components/icons/NovarcIcons";
import { DownloadButton } from "./DownloadButton";
import { requestDownloadLink } from "../../services/api/robot";

interface Props {
  robotId;
  weldRuns: WeldRunWithFiles[];
  isCustomer;
  isLimitExceeded;
  TOOLTIP_DOWNLOAD_LIMIT;
  handleModalPreviewOpen;
  handleUsageUpdate;
}

export function NovaiWeldFilesView(props: Props) {
  const {
    robotId,
    weldRuns,
    isCustomer,
    isLimitExceeded,
    TOOLTIP_DOWNLOAD_LIMIT,
    handleModalPreviewOpen,
    handleUsageUpdate,
  } = props;

  const dataSource = weldRuns.map((e) => {
    return {
      startTime: formatDateAsFullText(e.arc_on_utc, e.time_zone, true),
      endTime: formatDateAsHHmmss(e.arc_off_utc, e.time_zone),
      weldInfo: `${e.work_order_ref} ${e.spool_ref} ${e.welder_id_ref}`,
      run_number: e.run_number,
      operator_name: e.operator_name,
      vid_filename: e.vid_filename,
      log_filename: e.log_filename,
    };
  });
  return (
    <>
      <Table
        columns={[
          {
            title: "Start Time",
            key: "startTime",
            dataIndex: "startTime",
          },
          {
            title: "End Time",
            key: "endTime",
            dataIndex: "endTime",
          },
          {
            title: "Weld Name",
            key: "weldInfo",
            dataIndex: "weldInfo",
          },
          {
            title: "Run",
            key: "run_number",
            dataIndex: "run_number",
          },
          {
            title: "Operator",
            key: "operator_name",
            dataIndex: "operator_name",
          },
          {
            title: "Videos",
            key: "videos",
            // dataIndex: "",
            render: (_, { vid_filename }) =>
              vid_filename === null ? (
                <Tooltip title="No video file linked to this weld run">
                  <CloseOutlined />
                </Tooltip>
              ) : (
                <span style={{ fontSize: 24 }}>
                  <Tooltip
                    title={
                      isLimitExceeded && isCustomer
                        ? TOOLTIP_DOWNLOAD_LIMIT
                        : "Click to preview"
                    }
                  >
                    <PreviewIcon
                      style={{
                        color: "#1890ff",
                        cursor:
                          isLimitExceeded && isCustomer
                            ? "not-allowed"
                            : "pointer",
                      }}
                      onClick={() => {
                        if (isLimitExceeded && isCustomer) {
                          return;
                        }
                        requestDownloadLink(robotId, {
                          key: vid_filename,
                          category: "NovEye/captures",
                        }).then((files) => {
                          // console.log("requesting preview", vid_filename, files);
                          if (files?.data?.length) {
                            handleModalPreviewOpen(files.data[0]);
                          }
                        });
                      }}
                    />
                  </Tooltip>{" "}
                  <DownloadButton
                    robotId={robotId}
                    fileName={vid_filename}
                    s3Key={vid_filename}
                    category={"NovEye/captures"}
                    isCustomer={isCustomer}
                    className="download-button-big"
                    handleUsageUpdate={handleUsageUpdate}
                  />
                </span>
              ),
          },
          {
            title: "Logs",
            key: "logs",
            // dataIndex: "",
            render: (_, { log_filename }) =>
              log_filename === null ? (
                <Tooltip title="No NovData log file linked to this weld run">
                  <CloseOutlined />
                </Tooltip>
              ) : (
                <span style={{ fontSize: 24 }}>
                  <DownloadButton
                    robotId={robotId}
                    fileName={log_filename}
                    s3Key={log_filename}
                    category={"NovData/Logs"}
                    isCustomer={isCustomer}
                    className="download-button-big"
                    handleUsageUpdate={handleUsageUpdate}
                  />
                </span>
              ),
          },
        ]}
        dataSource={dataSource}
      />
    </>
  );
}
