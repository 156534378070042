import PickerWithType from "../../../libs/components/PickerWithType";
import { Button } from "antd";
import moment from "moment";

interface LiteDateSelectorProps {
  onChange: (days: 1 | 7 | 30) => void;
  daysToShow: 1 | 7 | 30;
}

export function LiteDateSelector(props: LiteDateSelectorProps) {
  const { onChange, daysToShow } = props;

  return (
    <div>
      <Button
        onClick={() => {
          onChange(1);
        }}
        className={daysToShow === 1 ? "date-select-button-active" : ""}
      >
        Last 24 hours
      </Button>
      <Button
        onClick={() => {
          onChange(7);
        }}
        className={daysToShow === 7 ? "date-select-button-active" : ""}
      >
        Last 7 days
      </Button>
      <Button
        onClick={() => {
          onChange(30);
        }}
        className={daysToShow === 30 ? "date-select-button-active" : ""}
      >
        Last 30 days
      </Button>
    </div>
  );
}

interface FullDateSelectorProps {
  dateValues: [moment.Moment, moment.Moment];
  onChange: (
    dates: [moment.Moment, moment.Moment],
    dateStrings: [string, string]
  ) => void;
  lastSelectableDay: string;
}

export function CustomDateSelector(props: FullDateSelectorProps) {
  const { dateValues, onChange, lastSelectableDay } = props;
  return (
    <div className="Analytics_Date_Picker" style={{ textAlign: "right" }}>
      <PickerWithType
        type={"range"}
        defaultValue={dateValues}
        lastSelectableDay={lastSelectableDay}
        onChange={onChange}
        value={undefined}
      />
    </div>
  );
}