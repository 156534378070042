import { Switch, Table } from "antd";
import { getDailyReport } from "../robot/robotSearchUtils";
import type { S3File } from "../robot/robotSearchUtils";

import { ExclamationCircleOutlined } from "@ant-design/icons";

import { formatDateAsHmmssA } from "../../libs/utilsTyped";
import { useDispatch, useSelector } from "react-redux";
import { additionalFilesAction, setFilterDayView } from "../videos/videoSlice";
import type { WeldRunWithFiles } from "../robot/robotSlice";
import React, { useEffect } from "react";
import { RootState } from "../../redux/configureStore";
import { DownloadButton } from "./DownloadButton";
import { GroupedDailyReports } from "./GroupedDailyReports";

interface DayViewDataEntry {
  indexDate: Date;
  startTime: string;
  endTime: string;
  type: string;
}

interface DayViewWeldEntry extends DayViewDataEntry {
  type: "Weld";
  j: string;
  s: string;
  w: string;
  r: number;
  vid: S3File;
  log: S3File;
  duration: number;
}

interface DayViewFileEntry extends DayViewDataEntry {
  robot_id: string;
  created_date: string;
  uploaded_date: string;
  s3_key: string;
  file_name: string;
  publisher: string;
  weld_id: string;
}

interface Props {
  selectedDate;
  reports;
  isCustomer;
  handleModalPreviewOpen;
  handleUsageUpdate;
  robotId;
  filteredWeldRun;
}

export default function (props: Props) {
  const {
    selectedDate,
    reports,
    isCustomer,
    handleModalPreviewOpen,
    handleUsageUpdate,
    robotId,
    filteredWeldRun,
  } = props;

  const dispatch = useDispatch();
  const eventFilter = useSelector<
    RootState,
    { welds: boolean; additionalFiles: boolean }
  >((state) => state.videos.dailyEventView);

  const additionalFiles = useSelector<RootState, any[]>(
    (state) => state.videos.additionalFiles,
  );

  const weldData: DayViewWeldEntry[] = filteredWeldRun.map((e) => {
    const start = new Date(e.arc_on_utc);
    const end = new Date(e.arc_off_utc);
    const indexDate = start;
    // @ts-ignore
    const duration = end - start;
    const localStartTime = formatDateAsHmmssA(e.arc_on_utc, e.time_zone);
    const localEndTime = formatDateAsHmmssA(e.arc_off_utc, e.time_zone);
    return {
      indexDate,
      duration,
      startTime: localStartTime,
      endTime: localEndTime,
      type: "Weld",
      j: e.work_order_ref,
      s: e.spool_ref,
      w: e.welder_id_ref,
      r: e.run_number,
      vid: e.vid_filename,
      log: e.log_filename,
    };
  });
  const filteredWeldData = eventFilter.welds ? weldData : [];

  const otherFiles: DayViewFileEntry[] = additionalFiles.map((e) => {
    const indexDate = new Date(e.uploaded_date);
    const formattedTime = indexDate.toLocaleTimeString("en-US");
    return {
      indexDate,
      startTime: formattedTime,
      endTime: formattedTime,
      type: e.publisher,
      ...e,
    };
  });

  const all: DayViewDataEntry[] = [...filteredWeldData, ...otherFiles].sort(
    // @ts-ignore
    (a, b) => a.indexDate - b.indexDate,
  );

  const dailyReports = [
    getDailyReport(reports, {
      arc_on_utc: selectedDate,
    } as WeldRunWithFiles),
  ].filter((e) => e);

  const columns = [
    {
      title: "Start",
      key: "startTime",
      dataIndex: "startTime",
    },
    {
      title: "End",
      key: "endTime",
      dataIndex: "endTime",
    },
    {
      title: "Duration",
      key: "duration",
      render: (_, item) => {
        if (item.type === "Weld" && item.duration > 0) {
          const m = Math.trunc(item.duration / 60000);
          // remainder
          const s = Math.trunc((item.duration - 60000 * m) / 1000);
          if (item.duration < 60000) {
            return (
              <span>
                <span>{s}s</span>
                <span
                  title={
                    "Short Weld duration, possibly cold run or incomplete weld"
                  }
                >
                  {" "}
                  <ExclamationCircleOutlined />
                </span>
              </span>
            );
          }
          return (
            <span>
              {m}m {s}s
            </span>
          );
        }
        return "";
      },
    },
    {
      title: "Event",
      key: "type",
      dataIndex: "type",
    },
    {
      title: "File Download",
      key: "files",
      render: (_, item) => {
        return (
          <span>
            {item?.type === "Weld" ? (
              <WeldRow
                j={item.j}
                s={item.s}
                w={item.w}
                r={item.r}
                vid={
                  item.vid ? (
                    <span>
                      [Vid:{" "}
                      <DownloadButton
                        robotId={robotId}
                        fileName={item.vid}
                        s3Key={item.vid}
                        category={"NovEye/captures"}
                        isCustomer={isCustomer}
                        className="download-button-big"
                        handleUsageUpdate={handleUsageUpdate}
                      />
                      ]
                    </span>
                  ) : (
                    <em>Vid not found</em>
                  )
                }
                log={
                  item.log ? (
                    <span>
                      [Log:{" "}
                      <DownloadButton
                        robotId={robotId}
                        fileName={item.log}
                        s3Key={item.log}
                        category={"NovData/Logs"}
                        isCustomer={isCustomer}
                        className="download-button-big"
                        handleUsageUpdate={handleUsageUpdate}
                      />
                      ]
                    </span>
                  ) : (
                    <em>Log not found</em>
                  )
                }
              />
            ) : (
              <FileRow
                name={item.file_name}
                file={
                  <DownloadButton
                    robotId={robotId}
                    fileName={item.file_name}
                    s3Key={item.s3_key}
                    category={item.type}
                    isCustomer={isCustomer}
                    className="download-button-big"
                    handleUsageUpdate={handleUsageUpdate}
                  />
                }
              />
            )}
          </span>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(
      additionalFilesAction({
        id: robotId,
        start: selectedDate,
        end: selectedDate,
      }),
    );
  }, [dispatch, robotId, selectedDate]);

  return (
    <>
      <div style={{ display: "flex", gap: 10 }}>
        <label style={{ fontWeight: "bold" }}>Show/Hide:</label>
        <Switch
          checkedChildren="Welds"
          unCheckedChildren="Welds"
          onChange={(v) => dispatch(setFilterDayView({ welds: v }))}
          checked={eventFilter.welds}
          title="Weld runs completed on this day"
        />
        <Switch
          checkedChildren="Other files"
          unCheckedChildren="Other files"
          onChange={(v) => dispatch(setFilterDayView({ additionalFiles: v }))}
          checked={eventFilter.additionalFiles}
          title="Files uploaded on this day but not matched to a Weld on the same day"
        />
      </div>
      <GroupedDailyReports
        robotId={robotId}
        reportFiles={dailyReports}
        handleModalPreviewOpen={handleModalPreviewOpen}
        handleUsageUpdate={handleUsageUpdate}
      />
      {!dailyReports.length && (
        <div style={{width:"100%", display:"flex", justifyContent:"end"}}>No Daily report found on {selectedDate}</div>
      )}
      <Table
        className="calendar-modal-day-view"
        columns={columns}
        dataSource={all}
      ></Table>
    </>
  );
}

function WeldRow({ j, s, w, r, vid, log }) {
  return (
    <div
      style={{
        display: "flex",
        gap: 10,
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <div style={{ display: "flex", gap: 5 }}>
        <span title="Job ID" style={{ color: "#dd0000" }}>
          {j}
        </span>
        <span title="Spool ID" style={{ color: "#ddaa00" }}>
          {s}
        </span>
        <span title="Weld ID" style={{ color: "#00aadd" }}>
          {w}
        </span>
        <span title="Run Num" style={{ color: "#0000dd" }}>
          {r}
        </span>
      </div>
      <div
        style={{
          display: "flex",
          gap: 10,
        }}
      >
        <div>{vid}</div>
        <div>{log}</div>
      </div>
    </div>
  );
}

function FileRow({ name, file }) {
  return (
    <div className={`file-upload-entry`}>
      <div>{name}</div>
      <div>{file}</div>
    </div>
  );
}
