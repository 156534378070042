import { Tooltip } from "antd";
import {
  DownloadOutlined,
  FileExcelOutlined,
  FileUnknownOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useLoadingWithProgress } from "../../libs/useLoading";
import { getRobotTier } from "../robot/robotSlice";
import { RootState } from "../../redux/configureStore";
import React, { useEffect, useState } from "react";
import { requestDownloadLink } from "../../services/api/robot";
import { S3File } from "../robot/robotSearchUtils";

const TOOLTIP_DOWNLOAD_LIMIT = (
  <span style={{ fontSize: "0.8rem" }}>
    {"Download limit exceeded"}
    <br />
    {
      "Sorry, you can't view or download at this time. Contact Novarc Customer Support to view file or increase limits"
    }
  </span>
);

interface Props {
  robotId: number;
  fileName: string;
  s3Key: string;
  category: string;
  className: string;
  isCustomer: boolean;
  handleUsageUpdate;
}

export function DownloadButton(props: Props) {
  const {
    robotId,
    fileName,
    s3Key,
    category,
    className,
    isCustomer,
    handleUsageUpdate,
  } = props;

  const [[robotTier], progress] = useLoadingWithProgress<
    any,
    RootState["robot"]
  >("robot", ({ tier }) => [tier?.[robotId]], [getRobotTier]);

  const [done, setDone] = useState(false);
  const [download, setDownload] = useState<S3File | null>(null);

  const isLimitExceeded =
    isCustomer &&
    robotTier &&
    parseInt(robotTier.used, 10) >= parseInt(robotTier.capacity, 10);

  const downloadLink = download?.url;
  const size = download?.size;

  useEffect(() => {
    // console.warn(robotId, s3Key, category);
    if (robotId && s3Key) {
      requestDownloadLink(robotId, { key: s3Key, category }).then((res) => {
        setDone(true);
        if (res?.data?.length) {
          setDownload(res.data[0]);
        }
      });
    }
  }, [robotId, s3Key, category]);

  return (
    <>
      {isLimitExceeded ? (
        <Tooltip title={TOOLTIP_DOWNLOAD_LIMIT}>
          <FileExcelOutlined />
        </Tooltip>
      ) : (
        <Tooltip
          title={
            !done ? (
              "Loading..."
            ) : downloadLink ? (
              "Download"
            ) : (
              <div>
                <p>File not found</p>
                <p>File "{fileName}" may have not been uploaded to Cloud.</p>
              </div>
            )
          }
        >
          {!done ? (
            <LoadingOutlined />
          ) : downloadLink ? (
            <a
              className={"videos-reports-download " + className}
              title={fileName}
              href={isLimitExceeded ? null : downloadLink}
              target="_blank"
              rel="noopener noreferrer"
              download
              type="application/octet-stream"
            >
              <DownloadOutlined
                style={{
                  cursor: isLimitExceeded ? "not-allowed" : "pointer",
                }}
                onClick={() =>
                  isLimitExceeded
                    ? null
                    : handleUsageUpdate(robotId, {
                        size: download.size,
                        fileName: download.fileName,
                        activity: "download",
                      })
                }
              />
              <span className="file-size"> {formatFileSize(size)}</span>
            </a>
          ) : (
            <span title={fileName}>
              <FileUnknownOutlined />
            </span>
          )}
        </Tooltip>
      )}
    </>
  );
}

function formatFileSize(bytes) {
  if (bytes / 1024 < 1000) {
    return `${(bytes / 1024).toFixed(1)}kB`;
  }
  if (bytes / (1024 * 1024) < 1000) {
    return `${(bytes / (1024 * 1024)).toFixed(1)}MB`;
  } else {
    return `${(bytes / (1024 * 1024 * 1024)).toFixed(1)}GB`;
  }
}
