import React, { useEffect, useState, useRef } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { DatePicker, Select } from "antd";
import LoadingPage from "../../components/LoadingPage";
import { LOADING_ANIMATION_SIZE_FULL } from "../../libs/constants";
import useRobotTimezone from "../../libs/useRobotTimezone";
import moment from "moment-timezone";

function AlertOverlay(props) {
  const {
    style,
    title,
    alertGreen,
    alertRed,
    alertYellow,
    alertOther,
    onChange,
  } = props;
  const [filter, setFilter] = useState(null);
  useEffect(() => {
    onChange(filter);
  }, [filter]);

  return (
    <div className="calendar-alert-overlay" style={style}>
      <h1>{title}</h1>
      <button
        className={
          "calendar-alert-green" +
          (!filter ? "" : filter === "green" ? " active" : " inactive")
        }
        onClick={() => setFilter(filter === "green" ? null : "green")}
      >
        <span className="value">{alertGreen.value}</span>
        <CheckOutlined />
      </button>
      <button
        className={
          "calendar-alert-red" +
          (!filter ? "" : filter === "red" ? " active" : " inactive")
        }
        onClick={() => setFilter(filter === "red" ? null : "red")}
      >
        <span className="value">{alertRed.value}</span>
        <CloseOutlined />
      </button>
      <button
        className={
          "calendar-alert-yellow" +
          (!filter ? "" : filter === "yellow" ? " active" : " inactive")
        }
        onClick={() => setFilter(filter === "yellow" ? null : "yellow")}
      >
        <span className="value">{alertYellow.value}</span>
        <span>{alertYellow.label}</span>
      </button>
      <div className="separator" />
      <button
        className={
          "calendar-alert-gray" +
          (!filter ? "" : filter === "other" ? " active" : " inactive")
        }
        onClick={() => setFilter(filter === "other" ? null : "other")}
      >
        <span>{alertOther.label}</span>
        <span className="value">{alertOther.value}</span>
      </button>
    </div>
  );
}

export default function Calendar(props) {
  const {
    robotId,
    className,
    onDateChange,
    overlay,
    events,
    eventClick,
    eventClassNames,
    onDateClick,
    datePickerEvents,
    loadingProgress,
  } = props;
  const calendarRef = useRef(null);
  const [calendarStart, setCalendarStart] = useState("");
  const [calendarEnd, setCalendarEnd] = useState("");
  const [rerender, setRerender] = useState(false);

  const [alertsDynamicPosition, setAlertsDynamicPosition] = useState({
    display: "none",
    left: 0,
  });
  const tz = useRobotTimezone(true);
  const now = moment.tz(tz).utc(true).toISOString();

  useEffect(() => {
    // eslint wants onDateChange to be added to useeffect dep array
    // but doing so causes it to endlessly fire
    if (calendarStart && calendarEnd) {
      onDateChange(calendarStart, calendarEnd);
    }
  }, [calendarStart, calendarEnd, robotId]);

  // due to a bug in the FullCalendar library,
  //  changing the now value after rendering does not update the Today marker
  //  as a workaround, we forcibly unload and rerender the FullCalendar
  useEffect(() => {
    setRerender(true);
  }, [tz]);

  if (rerender) {
    // the timeout value does not matter, but setRerender(false) cannot be synchronous
    // if synchronous, the framework skips the rendering step
    setTimeout(() => setRerender(false), 1);
    return <LoadingPage size={LOADING_ANIMATION_SIZE_FULL} />;
  }

  if (loadingProgress) {
    if (!loadingProgress.isFinished()) {
      return (
        <LoadingPage
          size={LOADING_ANIMATION_SIZE_FULL}
          progress={loadingProgress}
        />
      );
    }
  }

  return (
    <div className={"calendar-container " + className}>
      {!!overlay && <AlertOverlay {...overlay} style={alertsDynamicPosition} />}
      <FullCalendar
        // This will bypass all of timezone handling from browsers and libraries (momentjs, fullcalendar)
        // To provide consistent timestamp
        timeZone="UTC"
        ref={calendarRef}
        headerToolbar={{
          left: "today,prev,next,title",
          center: "",
          right: "goToDate,changeView",
        }}
        height={"90vh"}
        now={now}
        nowIndicator={true}
        plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
        initialView="dayGridMonth"
        customButtons={{
          goToDate: {
            // https://fullcalendar.io/docs/v6/customButtons
            // behavior of Fullcalendar library when "text" is not a plain string is not well defined
            // Prescribed use is to specify "text" and "click" properties for a custom button
            text: (
              <DatePicker
                style={{ width: 120 }}
                onChange={(date, dateString) => {
                  if (date) calendarRef.current.getApi().gotoDate(dateString);
                }}
                showToday={false}
                dateRender={(current) => {
                  if(current.format("YYYY-MM-DD") === now.substring(0,10)){
                    return (
                        <div
                            className={
                                "ant-picker-cell-inner ant4-workaround"
                            }
                        >
                          {current.date()}
                        </div>
                    );
                  }
                  return (
                    <div
                      className={
                        "ant-picker-cell-inner " +
                        (datePickerEvents?.includes(current.format("YYYY-MM-DD"))
                          ? "circled-date"
                          : "")
                      }
                    >
                      {current.date()}
                    </div>
                  );
                }}
              />
            ),
            // Specifying a hint prevents the calendar from attempting to stringify the object
            // and producing "[object Object]"
            hint: "Go to Date",
          },
          changeView: {
            text: (
              <Select
                defaultValue="month"
                style={{ width: 100 }}
                onChange={(view) => {
                  calendarRef.current.getApi().changeView(view);
                }}
              >
                <Select.Option value="timeGridDay">Day</Select.Option>
                <Select.Option value="timeGridWeek">Week</Select.Option>
                <Select.Option value="dayGridMonth">Month</Select.Option>
              </Select>
            ),
            hint: "Change View",
          },
        }}
        events={events}
        eventClick={eventClick}
        eventClassNames={eventClassNames}
        eventDidMount={({ event, el }) => {
          el.setAttribute("title", event.title);
        }}
        dayMaxEventRows={4}
        showNonCurrentDates={false}
        navLinks={!!onDateClick}
        navLinkDayClick={onDateClick}
        viewDidMount={({ view, el }) => {
          // console.log("viewdidmount", view, el);
          try {
            // at render time, find the size/position of the calendar title
            // and adjust the alert bar's position to display in line with and to the right of the title
            const calendarWidth = el.parentElement.clientWidth;
            const headerTopLeftWidth =
              el.parentElement.parentElement.querySelector(".fc-header-toolbar")
                .children[0].clientWidth;
            const headerTopRightWidth =
              el.parentElement.parentElement.querySelector(".fc-header-toolbar")
                .children[2].clientWidth;
            const width =
              calendarWidth - headerTopLeftWidth - headerTopRightWidth;
            setAlertsDynamicPosition({
              left: headerTopLeftWidth,
              width: width,
            });
          } catch (e) {
            console.warn("failed to position calendar alert overlay", e);
          }
        }}
        datesSet={(dateInfo) => {
          // see https://fullcalendar.io/docs/datesSet
          // calendar range (>= start < end)
          // for example May has start=2024-05-01 end=2024-06-01
          // timezone offset is discarded
          setCalendarStart(dateToYMD(dateInfo.start, false));
          setCalendarEnd(dateToYMD(dateInfo.end, false));
        }}
      />
    </div>
  );
}

function dateToYMD(date, compensateTimezone = true) {
  return (
    (
      compensateTimezone
        ? new Date(date.getTime() - date.getTimezoneOffset() * 60000)
        : date
    )
      .toISOString()
      //YYYY-MM-DD
      .substring(0, 10)
  );
}
