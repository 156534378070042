import { List, Switch, Table, Tooltip } from "antd";
import {
  getDailyReport,
  getNovDataLogLink,
  getVideoLink,
} from "./robotSearchUtils";
import type { S3File } from "./robotSearchUtils";
import {
  AttachmentIcon,
  PreviewIcon,
} from "../../components/icons/NovarcIcons";
import {
  DownloadOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

import {
  formatDateAsHmmssA,
  getStartEndOfDay,
  isBetweenDates,
} from "../../libs/utilsTyped";
import { useDispatch, useSelector } from "react-redux";
import { setFilterDayView } from "../videos/videoSlice";
import type {WeldRunWithFiles} from "./robotSlice";

interface DayViewDataEntry {
  indexDate: Date;
  startTime: string;
  endTime: string;
  type: "Weld" | "Video upload" | "Log upload";
}

interface DayViewWeldEntry extends DayViewDataEntry {
  type: "Weld";
  j: string;
  s: string;
  w: string;
  r: number;
  vid: S3File;
  log: S3File;
  duration: number;
}

interface DayViewFileEntry extends DayViewDataEntry, S3File {
  type: "Video upload" | "Log upload";
}

export default function (props) {
  const {
    selectedDate,
    reports,
    isLimitExceeded,
    isCustomer,
    TOOLTIP_DOWNLOAD_LIMIT,
    handleModalPreviewOpen,
    showTimeOut,
    TOOLTIP_WARNING,
    handleUsageUpdate,
    robotId,
    DownloadButton,
    videos,
    novdata_logs,
    filteredWeldRun,
  } = props;

  const dispatch = useDispatch();
  const eventFilter: {
    welds: boolean;
    matchedFiles: boolean;
    unmatchedFiles: boolean;
  } = useSelector(
    // @ts-ignore
    (state) => state.videos.dailyEventView
  );
  const [startDate, endDate] = getStartEndOfDay(selectedDate);

  const weldData: DayViewWeldEntry[] = filteredWeldRun.map((e) => {
    const start = new Date(e.arc_on_utc);
    const end = new Date(e.arc_off_utc);
    const indexDate = start;
    // @ts-ignore
    const duration = end - start;
    const localStartTime = formatDateAsHmmssA(e.arc_on_utc, e.time_zone);
    const localEndTime = formatDateAsHmmssA(e.arc_off_utc, e.time_zone);
    return {
      indexDate,
      duration,
      startTime: localStartTime,
      endTime: localEndTime,
      type: "Weld",
      j: e.work_order_ref,
      s: e.spool_ref,
      w: e.welder_id_ref,
      r: e.run_number,
      vid: getVideoLink(videos, e),
      log: getNovDataLogLink(novdata_logs, e),
    };
  });
  const filteredWeldData = eventFilter.welds ? weldData : [];
  const matchedVids = new Set(
    weldData.map((e) => e.vid?.fileName).filter((e) => e)
  );
  const matchedLogs = new Set(
    weldData.map((e) => e.log?.fileName).filter((e) => e)
  );
  const currentDayVids = videos
    .filter((e: S3File) => isBetweenDates(e.uploadedAt, startDate, endDate))
    .map((e: S3File) => ({ ...e, isMatched: matchedVids.has(e.fileName) }));
  const currentDayLogs = novdata_logs
    .filter((e: S3File) => isBetweenDates(e.uploadedAt, startDate, endDate))
    .map((e: S3File) => ({ ...e, isMatched: matchedLogs.has(e.fileName) }));
  const filteredVids =
    eventFilter.matchedFiles && eventFilter.unmatchedFiles
      ? currentDayVids
      : eventFilter.matchedFiles
      ? currentDayVids.filter((e) => e.isMatched)
      : eventFilter.unmatchedFiles
      ? currentDayVids.filter((e) => !e.isMatched)
      : [];
  const filteredLogs =
    eventFilter.matchedFiles && eventFilter.unmatchedFiles
      ? currentDayLogs
      : eventFilter.matchedFiles
      ? currentDayLogs.filter((e) => e.isMatched)
      : eventFilter.unmatchedFiles
      ? currentDayLogs.filter((e) => !e.isMatched)
      : [];

  const vidData: DayViewFileEntry[] = filteredVids.map((e: S3File) => {
    const indexDate = new Date(e.uploadedAt);
    const localTime = indexDate.toLocaleTimeString("en-US");
    return {
      indexDate,
      startTime: localTime,
      endTime: localTime,
      type: "Video upload",
      ...e,
    };
  });
  const logData: DayViewFileEntry[] = filteredLogs.map((e: S3File) => {
    const indexDate = new Date(e.uploadedAt);
    const localTime = indexDate.toLocaleTimeString("en-US");
    return {
      indexDate,
      startTime: localTime,
      endTime: localTime,
      type: "Log upload",
      ...e,
    };
  });
  const all: DayViewDataEntry[] = [...filteredWeldData, ...vidData, ...logData].sort(
    // @ts-ignore
    (a, b) => a.indexDate - b.indexDate
  );

  const columns = [
    {
      title: "Start",
      key: "startTime",
      dataIndex: "startTime",
    },
    {
      title: "End",
      key: "endTime",
      dataIndex: "endTime",
    },
    {
      title: "Duration",
      key: "duration",
      render: (_, item) => {
        if (item.type === "Weld" && item.duration > 0) {
          const m = Math.trunc(item.duration / 60000);
          // remainder
          const s = Math.trunc((item.duration - 60000 * m) / 1000);
          if (item.duration < 60000) {
            return (
              <span>
                <span>{s}s</span>
                <span
                  title={
                    "Short Weld duration, possibly cold run or incomplete weld"
                  }
                >
                  {" "}
                  <ExclamationCircleOutlined />
                </span>
              </span>
            );
          }
          return (
            <span>
              {m}m {s}s
            </span>
          );
        }
        return "";
      },
    },
    {
      title: "Event",
      key: "type",
      dataIndex: "type",
    },
    {
      title: "File Download",
      key: "files",
      render: (_, item) => {
        return (
          <span>
            {item?.type === "Weld" ? (
              <WeldRow
                j={item.j}
                s={item.s}
                w={item.w}
                r={item.r}
                vid={
                  item.vid ? (
                    <span>
                      [Vid:{" "}
                      <DownloadButton
                        isLimitExceeded={isLimitExceeded && isCustomer}
                        robotId={robotId}
                        item={item.vid}
                        handleUsageUpdate={handleUsageUpdate}
                        className="download-button-big"
                      />
                      ]
                    </span>
                  ) : (
                    <em>Vid not found</em>
                  )
                }
                log={
                  item.log ? (
                    <span>
                      [Log:{" "}
                      <DownloadButton
                        isLimitExceeded={isLimitExceeded && isCustomer}
                        robotId={robotId}
                        item={item.log}
                        handleUsageUpdate={handleUsageUpdate}
                        className="download-button-big"
                      />
                      ]
                    </span>
                  ) : (
                    <em>Log not found</em>
                  )
                }
              />
            ) : (
              <FileRow
                name={item.fileName}
                file={
                  <DownloadButton
                    isLimitExceeded={isLimitExceeded && isCustomer}
                    robotId={robotId}
                    item={item}
                    handleUsageUpdate={handleUsageUpdate}
                    className="download-button-big"
                  />
                }
                isMatched={item.isMatched}
              />
            )}
          </span>
        );
      },
    },
  ];

  return (
    <>
      <div style={{display:"flex", gap:10}}>
        <label style={{fontWeight:"bold"}}>
          Show/Hide:
        </label>
        <Switch
          checkedChildren="Welds"
          unCheckedChildren="Welds"
          onChange={(v) => dispatch(setFilterDayView({welds:v}))}
          checked={eventFilter.welds}
          title="Weld runs completed on this day"
        />
        <Switch
          checkedChildren="Orphaned file uploads"
          unCheckedChildren="Orphaned file uploads"
          onChange={(v) => dispatch(setFilterDayView({unmatchedFiles:v}))}
          checked={eventFilter.unmatchedFiles}
          title="Files uploaded on this day but not matched to a Weld on the same day"
        />
        <Switch
          checkedChildren="Matched file uploads"
          unCheckedChildren="Matched file uploads"
          onChange={(v) => dispatch(setFilterDayView({matchedFiles:v}))}
          checked={eventFilter.matchedFiles}
          title="Files uploaded on this day and matched to a Weld on the same day"
        />
      </div>
      {/*daily reports component copied from RobotSearchFiles*/}
      <List>
        {[getDailyReport(reports, { arc_on_utc: selectedDate } as WeldRunWithFiles)].map(
          (item) => {
            if (item) {
              return (
                <List.Item
                  actions={[
                    <span style={{ fontWeight: 600, cursor: "none" }}>
                      <Tooltip
                        title={
                          isLimitExceeded && isCustomer
                            ? TOOLTIP_DOWNLOAD_LIMIT
                            : null
                        }
                      >
                        <AttachmentIcon
                          style={{
                            cursor:
                              isLimitExceeded && isCustomer
                                ? "not-allowed"
                                : "none",
                          }}
                        />
                      </Tooltip>{" "}
                      Daily Report
                    </span>,
                    item ? (
                      <span
                        style={{
                          color: "#1890ff",
                          fontWeight: 600,
                          border: "1px solid #F0F0F0",
                          padding: "7px 8px",
                          cursor:
                            isLimitExceeded && isCustomer
                              ? "not-allowed"
                              : "pointer",
                        }}
                        onClick={() =>
                          isLimitExceeded && isCustomer
                            ? null
                            : handleModalPreviewOpen(item)
                        }
                      >
                        <Tooltip
                          title={
                            isLimitExceeded && isCustomer
                              ? TOOLTIP_DOWNLOAD_LIMIT
                              : null
                          }
                        >
                          <PreviewIcon
                            style={{
                              color: "#1890ff",
                              cursor:
                                isLimitExceeded && isCustomer
                                  ? "not-allowed"
                                  : "pointer",
                            }}
                          />
                        </Tooltip>{" "}
                        Preview
                      </span>
                    ) : showTimeOut ? (
                      <Tooltip title={TOOLTIP_WARNING}>
                        <ExclamationCircleOutlined />
                      </Tooltip>
                    ) : (
                      <LoadingOutlined />
                    ),

                    item ? (
                      <span>
                        <a
                          style={{
                            color: "#fff",
                            backgroundColor: "#3076B8",
                            padding: "7px 8px",
                          }}
                          href={isLimitExceeded && isCustomer ? null : item.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Tooltip
                            title={
                              isLimitExceeded && isCustomer
                                ? TOOLTIP_DOWNLOAD_LIMIT
                                : null
                            }
                          >
                            <DownloadOutlined
                              style={{
                                color: "#fff",
                                paddingRight: 4,
                                cursor:
                                  isLimitExceeded && isCustomer
                                    ? "not-allowed"
                                    : "pointer",
                              }}
                              onClick={() =>
                                isLimitExceeded && isCustomer
                                  ? null
                                  : handleUsageUpdate(robotId, item)
                              }
                            />
                          </Tooltip>
                          Download
                        </a>
                      </span>
                    ) : showTimeOut ? (
                      <Tooltip title={TOOLTIP_WARNING}>
                        <ExclamationCircleOutlined />
                      </Tooltip>
                    ) : (
                      <LoadingOutlined />
                    ),
                  ]}
                >
                  <div>
                    <span> </span>
                  </div>
                </List.Item>
              );
            } else {
              return (
                <List.Item>No Daily report found on {selectedDate}</List.Item>
              );
            }
          }
        )}
      </List>

      <Table
        className="calendar-modal-day-view"
        columns={columns}
        dataSource={all}
      ></Table>
    </>
  );
}

function WeldRow({ j, s, w, r, vid, log }) {
  return (
    <div
      style={{
        display: "flex",
        gap: 10,
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <div style={{ display: "flex", gap: 5 }}>
        <span title="Job ID" style={{ color: "#dd0000" }}>
          {j}
        </span>
        <span title="Spool ID" style={{ color: "#ddaa00" }}>
          {s}
        </span>
        <span title="Weld ID" style={{ color: "#00aadd" }}>
          {w}
        </span>
        <span title="Run Num" style={{ color: "#0000dd" }}>
          {r}
        </span>
      </div>
      <div
        style={{
          display: "flex",
          gap: 10,
        }}
      >
        <div>{vid}</div>
        <div>{log}</div>
      </div>
    </div>
  );
}

function FileRow({ name, file, isMatched }) {
  return (
    <div
      className={`file-upload-entry ${
        isMatched ? "file-upload-matched" : "file-upload-unmatched"
      }`}
    >
      <div>{name}</div>
      <div>{file}</div>
    </div>
  );
}
