/**
 * Constant Declaration
 */

export const DEV_IS_NOVAI = false;

// ##############################
// Page Route URL
// ##############################
export const ROUTER_ROOT = "/";
export const ROUTER_SIGNIN_ROOT = "/signin";

export const ROUTER_AUTH_ROOT = "/auth";
export const ROUTER_AUTH_WELCOME = ROUTER_AUTH_ROOT + "/welcome";
export const ROUTER_AUTH_PASSWORD_RECOVERY =
  ROUTER_AUTH_ROOT + "/reset_password";

export const ROUTER_ADMIN_ROOT = "/admin";
export const ROUTER_ADMIN_USERS_ROOT = ROUTER_ADMIN_ROOT + "/users";
export const ROUTER_ADMIN_USERS_NEW = ROUTER_ADMIN_USERS_ROOT + "/new";

export const ROUTER_WRIKE_KPI = "/wrike_kpi";

export const ROUTER_PROFILE_ROOT = "/profile";
export const ROUTER_PROFILE_PASSWORD = ROUTER_PROFILE_ROOT + "/password";
export const ROUTER_PROFILE_SETTING = ROUTER_PROFILE_ROOT + "/settings";

export const ROUTER_ROBOT_ROOT = "/:robotName";
export const ROUTER_ROBOT_ANALYTICS = ROUTER_ROBOT_ROOT + "/analytics";
export const ROUTER_ROBOT_VIDEOS = ROUTER_ROBOT_ROOT + "/videos";
export const ROUTER_ROBOT_SEARCH = ROUTER_ROBOT_ROOT + "/search";
export const ROUTER_ROBOT_REPORTS = ROUTER_ROBOT_ROOT + "/reports";
export const ROUTER_ROBOT_HEALTH = ROUTER_ROBOT_ROOT + "/health";
export const ROUTER_ROBOT_ALARM_LIST = ROUTER_ROBOT_ROOT + "/alarm_list";
export const ROUTER_ROBOT_STATISTICS = ROUTER_ROBOT_ROOT + "/statistics";
export const ROUTER_ROBOT_NOVEYE_LOGS = ROUTER_ROBOT_ROOT + "/noveye_logs";
export const ROUTER_ROBOT_NOVDATA_LOGS = ROUTER_ROBOT_ROOT + "/novdata_logs";
export const ROUTER_ROBOT_WELD_RESULTS =
  ROUTER_ROBOT_ROOT + "/weld_test_results";
export const ROUTER_ROBOT_SUPPORT = ROUTER_ROBOT_ROOT + "/support";

export const ROUTER_PERFORMANCE_ROOT = "/performance";
export const ROUTER_ROBOT_PERFORMANCE = ROUTER_ROBOT_ROOT + "/performance";

export const KEY_LOCAL_JWT_TOKEN = "jwt_token";

// URL
export const API_BASE_URL_PROD = "https://api.novarctech.com/api/v1";
// This will only be available to novarc group user
export const API_BASE_URL_BETA = "https://api-beta.novarctech.com/api/v1";
export const API_BASE_URL_STAGING = "https://api-staging.novarctech.com/api/v1";
export const API_BASE_URL_DEV = "http://localhost:3000/api/v1";

// export const API_AUTH_CHECK_TOKEN = API

const hostname = window && window.location && window.location.hostname;
export const IS_STAGING =
  hostname === "localhost" ||
  hostname === "development.app.novarctech.com" ||
  /staging.*\.app\.novarctech\.com/.test(hostname) ||
  hostname === "novarc-admin.netlify.app";

export const robotNameToId = {
  "171": 1,
  "185": 1805,
};
export const robotIdToName = {
  "1": "171",
  "1805": "185",
};

let apiURL;

if (IS_STAGING) {
  apiURL = API_BASE_URL_STAGING;
} else if (hostname === "beta.app.novarctech.com") {
  apiURL = API_BASE_URL_BETA;
} else if (hostname === "lvh.me" || hostname === "app.novarctech.com") {
  apiURL = API_BASE_URL_PROD;
} else {
  apiURL = API_BASE_URL_DEV;
}
const API_BASE_URL = apiURL;

// Auth API Routes
export const API_AUTH_ROOT = API_BASE_URL + "/auth";
export const API_AUTH_LOGIN = API_AUTH_ROOT + "/signin";
export const API_AUTH_REGISTER = API_AUTH_ROOT + "/register";
export const API_AUTH_RESET_PASSWORD = API_AUTH_ROOT + "/password";

// General API Routes
export const API_USERS_ROOT = API_BASE_URL + "/users";
export const API_USERS_ROBOTS = API_USERS_ROOT + "/robots";
export const API_UPLOAD_ROBOTS = API_BASE_URL + "/upload/robots";

export const API_WRIKE_URL = API_BASE_URL + "/wrike";
export const API_HUBSPOT_URL = API_BASE_URL + "/hubspot";

// Admin only API Routes
export const API_ADMIN_ROOT = API_BASE_URL + "/admin";
export const API_ADMIN_GROUPS = API_ADMIN_ROOT + "/groups";
export const API_ADMIN_USERS = API_ADMIN_ROOT + "/users";
export const API_ADMIN_ROLES = API_ADMIN_ROOT + "/roles";
export const API_ADMIN_ROBOTS = API_ADMIN_ROOT + "/robot";

// Timezone locale
export const DEFAULT_LOCALE = "en-CA";

// DEFAULT INITIAL START TIME
export const DEFAULT_INITIAL_DATE = "2019-01-01";

// REGEX EXPRESSION
export const DATETIME_REGEX =
  /([0-9]{4})(0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])-(2[0-3]|[01][0-9])([0-5][0-9])([0-5][0-9])/g;
export const DATE_REGEX =
  /([0-9]{4})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])/g;
export const LEGACY_WELD_REGEX =
  /(?:weld_vision.|NovEye.|log_|NovData-Log-)([\S]+)_([\S]+)_([\S]+)(?:-|_)([\d]+)/g;

// the 4-part pattern is used if we do not have a DB entry and need to guess parts from filename
export const NOVEYE_VIDEO_REVERSE_REGEX = /(?:weld_vision|NovEye)\.([^_]+)_([^_]+)_(.*)-(\d+)\.mp4/;

export const NOVEYE_VIDEO_TIMESTAMP_REGEX = /(?:weld_vision|NovEye)\.(20\d{6})-(\d+).mp4/;

// includes windows invalid chars + space
// does not include underscore
export const NOVEYE_SPECIAL_CHAR_REGEX = /[ "/<>:\\|?*]/g;

export const NOVDATA_SPECIAL_CHAR_REGEX = /["/<>:\\|?*]/g;

// matches and captures a sequence of 3 or 4 consecutive digits at the beginning of a string.
// why global flag? why match beginning and not end of input?
export const SWR_NUMBER_REGEX = /(^\d{3,4})/g;

// Fixme
// this is overly convoluted. I suggest Microsoft's pattern ^[^@\s]+@[^@\s]+\.[^@\s]+$ from https://learn.microsoft.com/en-us/dotnet/standard/base-types/how-to-verify-that-strings-are-in-valid-email-format
// ([^<>()[\].,;:\s@"+]+(\.[^<>()[\].,;:\s@"+]+)*) - Matches the local part of the email address before the @ symbol. It consists of one or more segments, separated by a dot (.). Each segment can contain any character except <>()[\].,;:\s@"+. This allows alphanumeric characters, - or _, and consecutive dots (e.g., john.doe@example.com).
// | - Alternation operator
// (".+") - Matches an email address enclosed in double quotes. why?
// @ - Matches the @ symbol.
// ([^<>()[\].,;:\s@"]+\.)+ - Matches the domain name in 1 or more segments ending with .
// [^<>()[\].,;:\s@"]{2,} - Matches the top-level domain (TLD) and requires it to be at least two characters long.
// note - matches start ^ but not end $ of input for some reason, no g flag
export const REGEX_EMAIL =
  /^(([^<>()[\].,;:\s@"+]+(\.[^<>()[\].,;:\s@"+]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})/;

// Wrike
export const CUSTOMER_SUPPORT_WRIKE_ID = "IEAB6KBHI4OTJDBY";
export const CUSTOMER_MAINTENANCE_WRIKE_ID = "IEAB6KBHI4UIHQXU";

export const WRIKE_TASK_PARAMS = {
  // status: "Active",
  sortField: "DueDate",
  sortOrder: "Desc",
  descendants: true,
  fields: "[subTaskIds, metadata, customFields, responsibleIds]",
  limit: 300,
};

// Design Config
export const CARD_CONFIG_STYLE = {
  title: "",
  size: "small",
  type: "inner",
  bordered: false,
};

// Company information
export const CUSTOMER_SUPPORT_EMAIL = "support@novarctech.com";
export const CUSTOMER_SUPPORT_PHONE = "+17787246717";
export const CUSTOMER_SUPPORT_PHONE_2 = "+18333668272";
export const CUSTOMER_SUPPORT_PHONE_3 = "1800644135";
export const CUSTOMER_SUPPORT_PHONE_FORMATTED = "+1 778 724-6717";
export const CUSTOMER_SUPPORT_PHONE_FORMATTED_2 = "1 833 366-8272";
export const CUSTOMER_SUPPORT_PHONE_FORMATTED_3 = "1 800 644-135";

// size (bounding box) of the custom loading animation on a full page
export const LOADING_ANIMATION_SIZE_FULL = 180;
export const LOADING_ANIMATION_SIZE = 100;
