import React, { useState } from "react";
import { Table } from "antd";

import Moment from "moment";
import "moment-timezone";
import { extendMoment } from "moment-range";

import {
  getPipeSchedule,
  convertInchesTomm,
} from "../../libs/utils";

import useRobotTimezone from "../../libs/useRobotTimezone";
import {groupWeldRunAnalytics} from "./videosAndReports/groupWeldRun";

const moment = extendMoment(Moment);

export function RobotWeldActivity(props) {
  const { weldRun, isMetric } = props;

  const [expandedKeys, setExpandedKeys] = useState([]);
  const timezone = useRobotTimezone(true);

  if (!weldRun) return null;

  const weldRunFilters = (column) => {
    return weldRun
      .map((item) => item[column])
      .filter((value, index, self) => self.indexOf(value) === index)
      .map((item) => {
        return { text: item, value: item };
      });
  };

  const weldRunColumns = (isParent) =>
    [
      {
        title: "Date",

        render: (_, item) => {
          // This will bypass all of timezone handling from browsers and libraries (momentjs, fullcalendar)
          // To provide consistent timestamp

          // Rendering weld time date
          // if it is grouped (item.children) then it will use last welded time
          if (item.children) {
            return (
              <span>
                {moment.tz(item.arc_off_utc, timezone)
                  .format("YYYY-MM-DD")}
              </span>
            );
          } else {
            // if it is grouped (item.children) then it will use the welded time
              console.warn(timezone, item.arc_on_utc, moment.tz(item.arc_on_utc, timezone)
                  .format("YYYY-MM-DD HH:mm:ss"))
            return (
              <span>
                {moment.tz(item.arc_on_utc, timezone)
                  .format("YYYY-MM-DD")}
              </span>
            );
          }
        },
      },
      {
        title: "Order",
        dataIndex: "work_order_ref",
        filters: weldRunFilters("work_order_ref"),
        onFilter: (value, record) => record.work_order_ref.indexOf(value) === 0,
      },
      {
        title: "Spool",
        dataIndex: "spool_ref",
        filters: weldRunFilters("spool_ref"),
        onFilter: (value, record) => record.spool_ref.indexOf(value) === 0,
      },
      !isParent && {
        title: "Weld ID",
        dataIndex: "welder_id_ref",
        // filters: weldRunFilters("welder_id_ref"),
        onFilter: (value, record) =>
          record.welder_id_ref && record.welder_id_ref.indexOf(value) === 0,
      },
      !isParent && {
        title: "Run",
        align: "left",
        dataIndex: "run_number", // should have count
      },
      {
        title: "Start Time",
        align: "left",
        render: (_, item) => {
          return (
            <span>
              {moment.tz(item.arc_on_utc, timezone)
                .format("HH:mm:ss")}
            </span>
          );
        },
      },
      {
        title: "Arc Off Time",
        align: "left",
        render: (_, item) => {
          if (item.duration) {
            return (
              <span>
                {Number(item.duration.hours()) * 60 +
                  Number(item.duration.minutes()) +
                  "m "}
                {item.duration.seconds() + "s"}
              </span>
            );
          } else {
            return <span>{null}</span>;
          }
        },
      },
      {
        title: "Arc On Time",
        align: "left",
        render: (_, item) => {
          return (
            <span>
              {Number(moment.duration({ ...item.weld_time }).hours()) * 60 +
                Number(moment.duration({ ...item.weld_time }).minutes()) +
                "m "}
              {moment.duration({ ...item.weld_time }).seconds() + "s"}
            </span>
          );
        },
      },
      {
        title: "Pipe Size",
        align: "left",
        filters: weldRun
          .map((item) => {
            return `${
              isMetric
                ? convertInchesTomm(item.pipe_size_inches).toFixed(0) + " mm"
                : item.pipe_size_inches + " in"
            }`;
          })
          .filter((value, index, self) => self.indexOf(value) === index)
          .map((item) => {
            return { text: item, value: item };
          }),
        onFilter: (value, record) => {
          const unit = isMetric ? "mm" : "in";
          return `${record.pipe_size_inches}${unit}` === value;
        },
        render: (_, item) => {
          const unit = isMetric ? "mm" : "in";
          return <span>{`${item.pipe_size_inches}${unit}`}</span>;
        },
      },
      !isParent && {
        title: "Pipe Sched",
        align: "left",
        filters: weldRun
          .map((item) => {
            return getPipeSchedule(item.pipe_schedule);
          })
          .filter((value, index, self) => self.indexOf(value) === index)
          .map((item) => {
            return { text: item, value: item };
          }),
        onFilter: (value, record) => {
          return record.pipe_schedule === value;
        },
        render: (_, item) => {
          return <span>{getPipeSchedule(item.pipe_schedule)}</span>;
        },
      },
      {
        title: "FDI",
        align: "left",
        render: (_, item) => {
          if (
            Number(moment.duration({ ...item.weld_time }).asMinutes()) >=
            Number(moment.duration({ minutes: 1 }).asMinutes())
          ) {
            return <span>{item.weld_fdi}</span>;
          } else {
            return <span></span>;
          }
        },
      },
      {
        title: "DI",
        align: "left",
        render: (_, item) => {
          if (
            Number(moment.duration({ ...item.weld_time }).asMinutes()) >=
            Number(moment.duration({ minutes: 1 }).asMinutes())
          ) {
            return <span>{item.weld_di}</span>;
          } else {
            return <span></span>;
          }
        },
      },
      !isParent && {
        title: "Operator",
        align: "center",
        dataIndex: "operator_name",
        // filters: weldRunFilters("operator_name"),
        onFilter: (value, record) => {
          if (record.children && record.children.length > 0) {
            return record.children
              .map((child) => child.operator_name.indexOf(value) === 0)
              .filter((c) => c);
          } else {
            return (
              record.operator_name && record.operator_name.indexOf(value) === 0
            );
          }
        },
      },
      !isParent && {
        title: "Procedure",
        align: "center",
        dataIndex: "procedure_name",
        // filters: weldRunFilters("procedure_name"),
        onFilter: (value, record) =>
          record.procedure_name && record.procedure_name.indexOf(value) === 0,
      },
    ].filter((item) => item);

  const pagination = { hideOnSinglePage: true };

  const groupedWeldRun = groupWeldRunAnalytics(weldRun,timezone,isMetric);

  const parentCols = weldRunColumns(true);
  const subCols = weldRunColumns(false);

  return (
    <Table
      className="WeldHistoryList_Table"
      size={"small"}
      columns={parentCols}
      dataSource={groupedWeldRun}
      onRow={({ key }) =>
        expandedKeys.includes(key) && { className: "expand-parent" }
      }
      expandable={{
        showExpandColumn: true,
        rowExpandable: (record) => !!record.items,

        onExpand: (expanded, { key }) => {
          const keys = expandedKeys;
          setExpandedKeys(
            expanded ? keys.concat(key) : keys.filter((k) => k !== key)
          );
        },
        expandedRowRender: (record) => {
          return (
            <Table
              className="WeldHistoryList_Table WeldHistoryList_Table_Nested"
              size={"small"}
              columns={subCols}
              dataSource={record.items}
              pagination={{
                ...pagination,
                pageSize: record.items.length,
              }}
              expandable={{
                showExpandColumn: false,
              }}
            />
          );
        },
      }}
      pagination={{ ...pagination, pageSize: groupedWeldRun.length }}
    />
  );
}
