import { Card, Col, Row, Skeleton, Typography } from "antd";
import { LOADING_ANIMATION_SIZE } from "../../../libs/constants";
import {
  RobotWeldHours,
  RobotWeldInches,
  RobotWeldOperators,
} from "../RobotPerformance";
import LoadingPage from "../../../components/LoadingPage";
import { useLoadingWithProgress } from "../../../libs/useLoading";
import {
  getRobotOperatorsWeldHoursList,
  getRobotOperatorsWeldInchesList,
  getRobotWeldHoursList,
  getRobotWeldInchesList,
  RobotSliceType,
} from "../robotSlice";
import type {ReactNode} from "react";

interface RobotWeldHoursEntry {
  robot_id: number;
  value: {
    hours?: number;
    minutes?: number;
    seconds?: number;
    milliseconds?: number;
  };
  date: string;
}

interface RobotWeldInchesEntry {
  id: number;
  value_di: number;
  value: number;
  date: string;
}

interface OperatorWeldHoursEntry {
  robot_id: number;
  weld_hours: {
    hours?: number;
    minutes?: number;
    seconds?: number;
    milliseconds?: number;
  };
  operator_name: string;
}

interface OperatorWeldInchesEntry {
  id: number;
  value_di: number;
  value: number;
  operator_name: string;
}

interface Props {
  range; //moment range
  robotId: number;
  datePickerComponent:ReactNode;
}

export default function RobotAnalyticsPerformance(props: Props) {
  const {
    range,
    robotId,
    datePickerComponent
  } = props;

  const [
    [
      robotWeldHours,
      robotWeldInches,
      robotWeldHoursOperators,
      robotWeldInchesOperators,
    ],
    chartsProgress,
  ] = useLoadingWithProgress<
    [
      RobotWeldHoursEntry[],
      RobotWeldInchesEntry[],
      OperatorWeldHoursEntry[],
      OperatorWeldInchesEntry[]
    ],
    RobotSliceType
  >(
    "robot",
    ({ weldHours, weldInches, weldHoursOperators, weldInchesOperators }) => [
      weldHours?.[robotId],
      weldInches?.[robotId],
      weldHoursOperators?.[robotId],
      weldInchesOperators?.[robotId],
    ],
    [
      getRobotWeldHoursList,
      getRobotWeldInchesList,
      getRobotOperatorsWeldHoursList,
      getRobotOperatorsWeldInchesList,
    ]
  );

  return (
    <>
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: 8,
          paddingRight: 8,
        }}
      >
        <Typography.Title style={{ color: "#082145" }} level={2}>
          Performance
        </Typography.Title>
        {datePickerComponent}
      </Row>

      <Row gutter={[16, 16]} style={{ padding: 8 }}>
        <Col span={8}>
          <Card title="Weld Inches" size="small" type="inner" bordered={false}>
            {chartsProgress.isSuccessful() ? (
              <RobotWeldInches weldData={robotWeldInches} range={range} />
            ) : (
              <Skeleton.Node
                active={true}
                style={{ height: 350, width: "100%", background: "white" }}
              >
                <LoadingPage size={LOADING_ANIMATION_SIZE} />
              </Skeleton.Node>
            )}
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Arc On Time" size="small" type="inner" bordered={false}>
            {chartsProgress.isSuccessful() ? (
              <RobotWeldHours weldData={robotWeldHours} range={range} />
            ) : (
              <Skeleton.Node
                active={true}
                style={{ height: 350, width: "100%", background: "white" }}
              >
                <LoadingPage size={LOADING_ANIMATION_SIZE} />
              </Skeleton.Node>
            )}
          </Card>
        </Col>
        <Col span={8}>
          <Card
            title="Operator Activity"
            size="small"
            type="inner"
            bordered={false}
          >
            {chartsProgress.isSuccessful() && robotWeldInchesOperators ? (
              <RobotWeldOperators
                weldInches={robotWeldInchesOperators.filter(
                  (i) => i.id === Number(robotId)
                )}
                weldHours={robotWeldHoursOperators.filter(
                  (i) => i.robot_id === Number(robotId)
                )}
              />
            ) : (
              <Skeleton.Node
                active={true}
                style={{ height: 350, width: "100%", background: "white" }}
              >
                <LoadingPage size={LOADING_ANIMATION_SIZE} />
              </Skeleton.Node>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
}
