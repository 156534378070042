import { Button, Input, Modal } from "antd";
import { useState } from "react";
import { createNote } from "./adminSlice";
import { useDispatch } from "react-redux";

interface Props {
  note: string;
  robot_id: number;
  openModal: boolean;
  setOpenModal: (open: boolean) => void;
}

export function EditNotes(props: Props) {
  const { robot_id, note, openModal, setOpenModal } = props;
  const [editing, setEditing] = useState(false);
  const [editedNote, setEditedNote] = useState(note);

  const [confirmModal, setConfirmModal] = useState(false);
  const dispatch = useDispatch();

  const handleOk = () => {
    if (editedNote !== note) {
      setConfirmModal(true);
      dispatch(createNote({ robot_id, note: editedNote }));
      setTimeout(() => {
        setOpenModal(false);
        setConfirmModal(false);
      }, 2000);
    } else {
      setOpenModal(false);
    }
  };

  const handleCancel = () => {
    setOpenModal(false);
  };

  if (!robot_id) {
    return null;
  }

  return (
    <Modal
      width={800}
      title={`Notes for ${robot_id}`}
      okText={"Save Changes and Close"}
      open={openModal}
      onOk={handleOk}
      confirmLoading={confirmModal}
      onCancel={handleCancel}
      destroyOnClose={true}
    >
      <div style={{marginBottom: 20}}>
        <Button onClick={() => setEditing(!editing)}>Click to Toggle Editing</Button>
      </div>
      <div>
        <Input.TextArea
          style={{ cursor: "text", minHeight: 200 }}
          // autoSize={{ minRows: 4, }}
          disabled={!editing}
          placeholder="Notes"
          value={editedNote}
          onChange={(event) => setEditedNote(event.target.value)}
        />
      </div>
    </Modal>
  );
}
