import moment from "moment-timezone";
import LoadingPage from "../../components/LoadingPage";
import { LOADING_ANIMATION_SIZE_FULL } from "../../libs/constants";
import Calendar from "../robot/Calendar";
import {
  getRobotTier,
  getRobotWeldFiles,
  updateRobotUsage,
} from "../robot/robotSlice";
import { getRobotReports } from "../reports/reportSlice";
import { Modal, Spin } from "antd";
import type { S3File } from "../robot/robotSearchUtils";

import { formatDateAsYYYYMMDD } from "../../libs/utilsTyped";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { normalizeRobotId } from "../../libs/utils";
import useRobotTimezone from "../../libs/useRobotTimezone";
import {
  combineLoadingProgress,
  useLoadingWithProgress,
} from "../../libs/useLoading";
import type { AppDispatch, RootState } from "../../redux/configureStore";
import { useDispatch } from "react-redux";
import Error404Page from "../../components/Error404Page";
import {
  groupWeldRunNovai,
  IGroupWeldRunNovai,
} from "../robot/videosAndReports/groupWeldRun";
import NovaiFilesDayView from "./NovaiFilesDayView";
import { GroupedDailyReports } from "./GroupedDailyReports";
import { NovaiWeldFilesView } from "./NovaiWeldFilesView";
import { VideoPlayer } from "../videos/VideosList";
import PreviewPDF from "../reports/PreviewPDF";

const TOOLTIP_DOWNLOAD_LIMIT = (
  <span style={{ fontSize: "0.8rem" }}>
    {"Download limit exceeded"}
    <br />
    {
      "Sorry, you can't view or download at this time. Contact Novarc Customer Support to view file or increase limits"
    }
  </span>
);

export default function NovaiCalendarView(props) {
  const dispatch = useDispatch<AppDispatch>();

  const [selectedJob, setSelectedJob] = useState<IGroupWeldRunNovai | null>(
    null,
  );
  const [selectedItem, setSelectedItem] = useState<S3File | null>(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedJobDateRange, setselectedJobDateRange] = useState(null);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalPreviewOpen, setModalPreviewOpen] = useState(false);
  const [modalDateOpen, setModalDateOpen] = useState(false);

  const { robotName } = useParams();
  const { isCustomer } = props;

  const robotId = normalizeRobotId(robotName);

  const robotTimezone = useRobotTimezone(true);

  const [[robotWeldRunFiles, robotTier], robotLoading] = useLoadingWithProgress<
    any,
    RootState["robot"]
  >("robot", (state) => [state.robotFiles?.[robotId], state.tier?.[robotId]], [
    getRobotWeldFiles,
    getRobotTier,
  ]);
  const [reports, reportsLoading] = useLoadingWithProgress<
    any,
    RootState["reports"]
  >("reports", (state) => state.reports, getRobotReports);
  const allFilesLoading = combineLoadingProgress(robotLoading, reportsLoading);
  if (allFilesLoading.isFinished() && !allFilesLoading.isSuccessful()) {
    console.error("error while fetching files", robotLoading, reportsLoading);
  }
  const groupedWeldRun = groupWeldRunNovai(robotWeldRunFiles, robotTimezone);

  useEffect(() => {
    dispatch(getRobotTier(robotId));
  }, [dispatch, robotId]);

  useEffect(() => {
    if (selectedJobDateRange?.start || selectedJobDateRange?.end) {
      dispatch(getRobotReports({ id: robotId, ...selectedJobDateRange }));
    }
  }, [dispatch, robotId, selectedJobDateRange]);
  const handleCancel = () => {
    dispatch(getRobotTier(robotId));
    setModalOpen(false);
  };

  const handleModalPreviewOpen = (item) => {
    setSelectedItem(item);
    handleUsageUpdate(robotId, { ...item, activity: "preview" });
    setModalPreviewOpen(true);
  };
  const handleModalPreviewClose = () => {
    setModalPreviewOpen(false);
  };

  const handleModalDateClose = () => {
    dispatch(getRobotTier(robotId));
    setModalDateOpen(false);
  };

  const handleUsageUpdate = (robotId, item) => {
    if (isCustomer) dispatch(updateRobotUsage({ id: robotId, payload: item }));
  };

  const isLimitExceeded =
    robotTier &&
    parseInt(robotTier.used, 10) >= parseInt(robotTier.capacity, 10);

  const filteredWeldRun = (robotWeldRunFiles ?? [])
    .filter((item) =>
      // check if local calendar day (not UTC day) is the same
      moment(formatDateAsYYYYMMDD(item.arc_on_utc, item.time_zone)).isSame(
        selectedDate,
      ),
    )
    .sort((a, b) => {
      if (a.arc_on_utc > b.arc_on_utc) {
        return 1;
      }
      if (a.arc_on_utc < b.arc_on_utc) {
        return -1;
      } else {
        return 0;
      }
    });

  let dailyReports = [];
  if (selectedJob) {
    // filter all unique days (yyyy-mm-dd) from current job
    const dates = new Set(
      selectedJob.children.map((e) => e.arc_on_utc.substring(0, 10)).sort(),
    );

    if (!!dates && dates.size > 1) {
      const datesArray = Array.from(dates);
      const start = datesArray[0];
      const end = datesArray.slice(-1)[0];
      const monthDiff = moment(end, "YYYY-MM").diff(
        moment(start, "YYYY-MM"),
        "months",
        true,
      );

      if (!selectedJobDateRange && monthDiff > 0) {
        const range = { start, end };
        setselectedJobDateRange(range);
      }
    }
    // match filename to see if it contains a date in current job
    dailyReports = (reports ?? []).filter((report) => {
      for (const date of dates) {
        if (report.fileName.includes(date)) return true;
      }
      return false;
    });
  }

  if (!robotId) {
    return <Error404Page />;
  }

  return (
    <>
      <Spin
        spinning={!robotLoading.isFinished()}
        indicator={
          <LoadingPage
            size={LOADING_ANIMATION_SIZE_FULL}
            progress={robotLoading}
          />
        }
      >
        <Calendar
          className="daily-reports"
          onDateChange={(calendarStart, calendarEnd) => {
            const range = { start: calendarStart, end: calendarEnd };
            dispatch(getRobotWeldFiles({ id: robotId, ...range }));
            dispatch(getRobotReports({ id: robotId, ...range }));
          }}
          robotId={robotId}
          events={groupedWeldRun}
          eventClick={(info) => {
            info.jsEvent.preventDefault();
            setModalOpen(true);
            setSelectedJob(info.event.extendedProps);
          }}
          onDateClick={(date) => {
            setModalDateOpen(true);
            const setDate = moment.utc(date.toUTCString()).format("YYYY-MM-DD");
            setSelectedDate(setDate);
          }}
          datePickerEvents={[]}
        />

        {!!selectedJob && (
          <Modal
            className="WeldVideos_Modal"
            title={`${selectedJob.work_order_ref} ${selectedJob.spool_ref} (${selectedJob.children.length}) runs`}
            open={modalOpen}
            onCancel={handleCancel}
            destroyOnClose={true}
            footer={null}
          >
            {dailyReports?.length ? (
              <GroupedDailyReports
                robotId={robotId}
                reportFiles={dailyReports}
                handleModalPreviewOpen={handleModalPreviewOpen}
                handleUsageUpdate={handleUsageUpdate}
              />
            ) : (
              <div />
            )}
            <NovaiWeldFilesView
              robotId={robotId}
              weldRuns={selectedJob.children}
              isCustomer={isCustomer}
              isLimitExceeded={isLimitExceeded}
              TOOLTIP_DOWNLOAD_LIMIT={TOOLTIP_DOWNLOAD_LIMIT}
              handleModalPreviewOpen={handleModalPreviewOpen}
              handleUsageUpdate={handleUsageUpdate}
            />
          </Modal>
        )}

        {!!selectedDate && (
          <Modal
            className="WeldVideos_Modal"
            title={moment(selectedDate).format("dddd, MMMM DD")}
            open={modalDateOpen}
            onCancel={handleModalDateClose}
            destroyOnClose={true}
            footer={null}
          >
            <NovaiFilesDayView
              selectedDate={selectedDate}
              reports={reports ?? []}
              isCustomer={isCustomer}
              handleModalPreviewOpen={handleModalPreviewOpen}
              handleUsageUpdate={handleUsageUpdate}
              robotId={robotId}
              filteredWeldRun={filteredWeldRun ?? []}
            />
          </Modal>
        )}

        {!!selectedItem?.fileName && (
          <Modal
            className="WeldVideos_Modal"
            open={modalPreviewOpen}
            centered
            bodyStyle={{ height: "80vh" }}
            width={"70%"}
            onOk={handleModalPreviewClose}
            onCancel={handleModalPreviewClose}
            destroyOnClose={true}
          >
            {selectedItem.fileName.endsWith(".pdf") ? (
              <PreviewPDF
                item={{ ...selectedItem, download: selectedItem.url }}
              />
            ) : selectedItem.fileName.endsWith(".mp4") ? (
              <VideoPlayer
                item={selectedItem}
                searchText={selectedItem.fileName}
                truncatedVideoName={selectedItem.fileName}
              />
            ) : (
              <div>Unable to detect file type for preview</div>
            )}
          </Modal>
        )}
      </Spin>
    </>
  );
}
