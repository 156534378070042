import React from "react";
import { useParams } from "react-router-dom";

import RobotSearchFiles from "../features/robot/RobotSearchFiles";
import RobotNavigation from "../features/robot/RobotNavigation";

import Error404Page from "./Error404Page";
import {DEV_IS_NOVAI} from "../libs/constants";
import NovaiCalendarView from "../features/novai/NovaiCalendarView";

function SearchFilesPage(props) {
  const { robotName } = useParams();
  const robotId = robotName.match(/(\d+)/) && robotName.match(/(\d+)/)[0];

  if (!robotId) {
    return <Error404Page />;
  }

  return (
    <>
      <RobotNavigation {...props} />
      {
        DEV_IS_NOVAI ? (
          <NovaiCalendarView {...props} />
        ) : (
          <RobotSearchFiles {...props} />
      )}
    </>
  );
}

export default SearchFilesPage;
