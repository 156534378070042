import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router-dom";

import ReactPlayer from "react-player";
import Highlighter from "react-highlight-words";

import moment from "moment";

import {
  Alert,
  Button,
  Card,
  DatePicker,
  Input,
  Modal,
  Space,
  Spin,
  Table,
  Tooltip,
} from "antd";
import {
  SearchOutlined,
  PlaySquareOutlined,
  DownloadOutlined,
} from "@ant-design/icons";

import {getRobotWeld, getRobotWeldFiles} from "../robot/robotSlice";
import { getRobotVideos, setHevcSupport, videoSliceKey } from "./videoSlice";

import LoadingPage from "../../components/LoadingPage";

import { fileSize, normalizeRobotId } from "../../libs/utils";
import {
  DATETIME_REGEX,
  LOADING_ANIMATION_SIZE_FULL,
  NOVEYE_VIDEO_REVERSE_REGEX,
} from "../../libs/constants";
import { useLoading, useLoadingWithProgress } from "../../libs/useLoading";

const RangePicker = DatePicker.RangePicker;

const NOVEYE_H265_WARNING = `This video is encoded with H.265 which is not supported by your browser.
Try using Safari version 13 or up, the most recent update of Chrome/Edge, 
or download this video and play it using a desktop app.`;

// ant4.x, not compatible with new API ant5.x
const rangePresets = {
  "Last 7 Days": [moment().add(-7, "days"), moment()],
  "Last 30 Days": [moment().add(-30, "days"), moment()],
  "Last 90 Days": [moment().add(-90, "days"), moment()],
  "1 year": [moment().add(-1, "year"), moment()],
  "All time": [moment("2017-01-01"), moment()],
};

const VideosList = (props) => {
  const dispatch = useDispatch();
  const { robotName } = useParams();
  const robotId = normalizeRobotId(robotName);

  const [videos, videoStateLoadingProgess] = useLoadingWithProgress(
    videoSliceKey,
    (vidState) => vidState.videos,
    getRobotVideos.typePrefix
  );

  const videoStateLoaded = videoStateLoadingProgess.isFinished();
  const [[robotWeldFiles, robotWeldRun], robotStateLoaded] = useLoading(
    "robot",
    ({ robotFiles, weldRun }) => [robotFiles?.[robotId], weldRun?.[robotId]],
    ([robotWeldFiles, robotWeldRun]) =>
      //   single != intentional to cover undefined | null
      robotWeldFiles != null && robotWeldRun != null
  );

  const weldRunIds = (robotWeldRun ?? []).map(e=>e.id);

  const [modalOpen, setModalOpen] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);

  const [dateRange, setDateRange] = useState([
    moment().add(-7, "days"),
    moment(),
  ]);
  const [dateFilterOn, setDateFilterOn] = useState(true);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const [pageNumber] = useState(1);
  const [defaultPageSize] = useState(12);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Clear
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput, 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleModalOpen = (item) => {
    setSelectedItem(item);
    setModalOpen(true);
    // dispatch(getRobotNovEyeLog(item.download));
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedItem(null);
  };

  useEffect(() => {
    dispatch(
      getRobotVideos({
        id: robotId,
        start: dateRange[0].format("YYYY-MM-DD"),
        end: dateRange[1].format("YYYY-MM-DD"),
      })
    );
    dispatch(getRobotWeld({ id: robotId }));
    dispatch(getRobotWeldFiles({
      id: robotId,
      start: dateRange[0].format("YYYY-MM-DD"),
      end: dateRange[1].format("YYYY-MM-DD"),
    }));
    // do not add dateRange, this should only run on first load
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dispatch, robotId]);

  if (!robotStateLoaded) {
    return (
      <LoadingPage
        size={LOADING_ANIMATION_SIZE_FULL}
        progress={robotStateLoaded}
      />
    );
  }

  const mergedVideo = !videos?.length
    ? []
    : videos
        .map((vid) => {
          const videoMatch = NOVEYE_VIDEO_REVERSE_REGEX.exec(vid.fileName);
          const weld_done =
            videoMatch &&
            robotWeldRun.find((weld) => {
              const weldRunId = weld.id;
              const match = robotWeldFiles.find((e) => e.weld_run_id === weldRunId);

              return vid.fileName === match?.vid_filename;
            });

          if (weld_done) {
            return {
              ...vid,
              ...weld_done,
              weld_done_at: weld_done.arc_on_utc,
            };
          } else if (vid.fileName.match(DATETIME_REGEX)) {
            const matchDateTime = [...vid.fileName.matchAll(DATETIME_REGEX)][0];

            return {
              ...vid,
              ...weld_done,

              weld_done_at: matchDateTime
                ? new Date(
                    matchDateTime[1],
                    parseInt(matchDateTime[2], 10) - 1,
                    matchDateTime[3],
                    matchDateTime[4],
                    matchDateTime[5],
                    matchDateTime[6]
                  ).toISOString()
                : null,
            };
          } else {
            return {
              ...vid,
              weld_done_at: vid.uploadedAt,
            };
          }
        })
        .filter((item) => {
          if (dateFilterOn && dateRange?.length === 2) {
            return moment(item.weld_done_at || item.uploadedAt).isBetween(
              dateRange[0].format("YYYY-MM-DD"),
              dateRange[1].format("YYYY-MM-DD")
            );
          }
          return false;
        })
        .sort((a, b) => {
          // this is desc sorting on date to sort chronologically
          const aString = a.weld_done_at || a.uploadedAt;
          const bString = b.weld_done_at || b.uploadedAt;

          if (aString > bString) {
            return -1;
          }
          if (aString < bString) {
            return 1;
          }
          return 0;
        });

  const columns = [
    {
      title: "File Name",
      dataIndex: "name",
      sorter: {
        compare: (a, b) => {
          const aString = a.name.toLowerCase();
          const bString = b.name.toLowerCase();

          if (aString > bString) {
            return 1;
          }
          if (aString < bString) {
            return -1;
          }
          return 0;
        },
      },
      ...getColumnSearchProps("name"),
    },
    {
      title: "Size",
      render: (_, item) => <span>{fileSize(item.size)}</span>,
    },
    {
      title: "Welded At",
      key: "weld_done_at",
      width: "20%",
      sorter: {
        compare: (a, b) => {
          const aString = a.weld_done_at;
          const bString = b.weld_done_at;

          if (aString > bString) {
            return 1;
          }
          if (aString < bString) {
            return -1;
          }
          return 0;
        },
      },
      render: (_, item) =>
        item.weld_done_at ? (
          <span>
            {moment(item.weld_done_at).format("YYYY-MM-DD, HH:mm:ss")}
          </span>
        ) : (
          <Tooltip title="Unable to retrieve welded time.">
            <span>({moment(item.date).format("YYYY-MM-DD, HH:mm:ss")})</span>
          </Tooltip>
        ),
    },
    {
      title: "Play",
      render: (_, item) => (
        <PlaySquareOutlined onClick={() => handleModalOpen(item)} />
      ),
    },

    {
      title: "Download",
      dataIndex: "",
      key: "download",
      render: (item) => (
        <a
          href={item.download}
          download
          target="_blank"
          rel="noopener noreferrer"
        >
          <DownloadOutlined />
        </a>
      ),
    },
  ];

  const data = mergedVideo.map((item, index) => {
    return {
      key: index,
      name: item.fileName,
      size: item.size,
      date: item.uploadedAt,
      download: item.url,
      ...item,
    };
  });

  return (
    <React.Fragment>
      <div className="date-picker-listview">
        Select date range:
        <RangePicker
          value={dateRange}
          ranges={rangePresets}
          onChange={(dates) => {
            setDateRange(dates);
            setDateFilterOn(false);
          }}
        />
        <button
          onClick={() => {
            if (!dateRange?.[0]) return;

            const start = dateRange[0].format("YYYY-MM-DD");
            const end = dateRange[1].format("YYYY-MM-DD");
            const isAllTime =
              rangePresets["All time"][0].format("YYYY-MM-DD") === start &&
              rangePresets["All time"][1].format("YYYY-MM-DD") === end;

            if (isAllTime) {
              dispatch(getRobotVideos({ id: robotId }));
            } else {
              dispatch(
                getRobotVideos({
                  id: robotId,
                  start: dateRange[0].format("YYYY-MM-DD"),
                  end: dateRange[1].format("YYYY-MM-DD"),
                })
              );
            }
            setDateFilterOn(true);
          }}
        >
          Load date range
        </button>
      </div>
      <Spin
        spinning={!videoStateLoaded}
        indicator={
          <LoadingPage
            size={LOADING_ANIMATION_SIZE_FULL}
            progress={videoStateLoadingProgess}
          />
        }
      >
        <Table
          columns={columns}
          dataSource={data}
          size="middle"
          pagination={{
            defaultPageSize: defaultPageSize,
            position: "bottom",
            pageNumber: pageNumber,
          }}
        />
      </Spin>
      {modalOpen && !!selectedItem && (
        <Modal
          title={selectedItem.name}
          visible={modalOpen}
          centered
          bodyStyle={{ height: "80%" }}
          width={"70%"}
          onOk={handleModalClose}
          onCancel={handleModalClose}
        >
          <VideoPlayer
            item={selectedItem}
            searchText={searchText}
            truncatedVideoName={""}
          />
        </Modal>
        // )
      )}
    </React.Fragment>
  );
};

export function VideoPlayer({ item, searchText, truncatedVideoName }) {
  const dispatch = useDispatch();
  const hevcSupport = useSelector((state) => state.videos.hevcSupport);

  return (
    item && (
      <Card
        title={
          <Tooltip title={item.name}>
            {item.name ? (
              <Highlighter
                highlightStyle={{
                  backgroundColor: "#ffc069",
                  padding: 0,
                }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={item.name ? truncatedVideoName : ""}
              />
            ) : (
              <span>{truncatedVideoName}</span>
            )}
          </Tooltip>
        }
      >
        {hevcSupport ? (
          <ReactPlayer
            url={item.download ?? item.url}
            width={"100%"}
            controls
            onError={(event) => {
              // onError passes an event, not the error object
              // the desired error message may be scoped to the browser runtime
              // and cannot be parsed by the program at load time
              // therefore we must attempt to play the video from the script
              // to receive and handle the error properly
              // console.log("reactplayer error",event);
              event.target.play().catch((err) => {
                if (err.name === "NotSupportedError") {
                  dispatch(setHevcSupport(false));
                }
              });
            }}
          />
        ) : (
          <Alert
            showIcon
            type="error"
            message={NOVEYE_H265_WARNING}
            description={
              <a href={item.download ?? item.url} download>
                To download, right click and select "save as"
              </a>
            }
          />
        )}
      </Card>
    )
  );
}

export default VideosList;
