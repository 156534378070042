import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { push } from "connected-react-router";

import { Button, Card, Col, Divider, Row, Typography } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

import Moment from "moment";
import { extendMoment } from "moment-range";

import { LOADING_ANIMATION_SIZE_FULL } from "../../libs/constants";

import { GetDateRange, normalizeRobotId } from "../../libs/utils";

import LoadingPage from "../../components/LoadingPage";
import Error404Page from "../../components/Error404Page";

import { RobotWeldActivity } from "./RobotWeldHistory";
import RobotStatsCard from "./RobotStatsCard";

import {
  getRobotWeld,
  getRobotLastWeldDone,
  getRobotTier,
  getRobotWeldHoursList,
  getRobotWeldInchesList,
  getRobotOperatorsWeldHoursList,
  getRobotOperatorsWeldInchesList,
} from "./robotSlice";
import StatusBanner from "../alarms/StatusBanner";
import { generateCsvReports } from "../reports/generateCsvReports";
import { useLoadingWithProgress } from "../../libs/useLoading";
import RobotAnalyticsPerformance from "./analytics/RobotAnalyticsPerformance";
import { PdfModal } from "./analytics/PdfModal";
import RobotCSWrike from "./analytics/RobotCSWrike";
import {
  CustomDateSelector,
  LiteDateSelector,
} from "./analytics/DateRangeSelector";
import useRobotTimezone from "../../libs/useRobotTimezone";

const moment = extendMoment(Moment);

const LOADING_DELAY_IN_MS = 1500;

function RobotAnalytics(props) {
  const { isCustomer } = props;
  const { robotName } = useParams();
  const robotId = normalizeRobotId(robotName);

  const robotTimezone = useRobotTimezone(true);

  const [[robotWeldRun, robotLastWeldRun, robotTier, ], progress] =
    useLoadingWithProgress(
      "robot",
      ({ weldRun, lastWeldRun, tier }) => [
        weldRun?.[robotId],
        lastWeldRun?.[robotId],
        tier?.[robotId],
      ],
      [getRobotWeld, getRobotLastWeldDone, getRobotTier]
    );

  const today = robotTimezone ? moment().tz(robotTimezone) : moment();
  const yesterday = today.clone().subtract(1, "days");
  const lastWeek = today.clone().subtract(7, "days");

  const last24Hours = {
    start: yesterday.format("YYYY-MM-DD"),
    end: today.format("YYYY-MM-DD"),
  };

  // dateValues is moment instance
  const [dateValues, setDateValues] = useState([lastWeek, today]);

  // dateRange is day string
  const dateRange = GetDateRange("range", dateValues);
  const [start, end] = dateRange;

  const [liteDaysToShow, setLiteDaysToShow] = useState(7); // default view as last 7 days

  const [openModal, setOpenModal] = useState(null);

  const [loadingDelay, setLoadingDelay] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRobotTier(robotId));

    //TODO: can this one be merged into the same daterange picker?
    dispatch(
      getRobotLastWeldDone({
        id: robotId,
        ...last24Hours,
      })
    );

    // this component expects date to always be defined
    // when date is empty (canceled selection in datepicker) do not reload
    if (!start || !end) {
      return;
    }

    dispatch(getRobotWeldHoursList({ id: robotId, start, end }));
    dispatch(getRobotWeldInchesList({ id: robotId, start, end }));
    dispatch(getRobotOperatorsWeldHoursList({ id: robotId, start, end }));
    dispatch(getRobotOperatorsWeldInchesList({ id: robotId, start, end }));
    dispatch(getRobotWeld({ id: robotId, start, end }));
  }, [dispatch, robotId, start, end]);

  useEffect(() => {
    setTimeout(() => {
      if (loadingDelay) {
        setLoadingDelay(false);
      }
    }, LOADING_DELAY_IN_MS);
  }, [loadingDelay]);

  useEffect(() => {
    if (robotTimezone) {
      setDateValues([lastWeek, today]);
    }
  }, [robotTimezone]);

  if (!robotId) {
    return <Error404Page />;
  }

  if (!progress.isFinished()) {
    return (
      <LoadingPage size={LOADING_ANIMATION_SIZE_FULL} progress={progress} />
    );
  }

  const isLite = robotTier?.tier === "lite";
  const isEssential = robotTier?.tier === "basic";

  let range = [];
  if (start && end) {
    //warning - never attempt to call moment.range with null values, it will hang
    range = Array.from(moment.range(start, end).by("day"));
  }

  const isMetric = robotWeldRun?.slice(-1)[0]?.units_system === "metric";

  const datePickerComponent =
    isCustomer && isLite ? (
      <LiteDateSelector
        daysToShow={liteDaysToShow}
        onChange={(d) => {
          setLoadingDelay(true);
          setLiteDaysToShow(d);
          setDateValues([today.clone().subtract(d, "days"), today]);
        }}
      />
    ) : (
      <CustomDateSelector
        dateValues={dateValues}
        lastSelectableDay={today.format("YYYY-MM-DD")}
        onChange={(values) => {
          setLoadingDelay(true);
          setDateValues(values);
        }}
      />
    );

  return (
    <div className="analytics-page">
      {isCustomer && (
        <>
          <StatusBanner
            robotName={robotName}
            weldData={robotLastWeldRun}
            showLink={
              <span style={{ fontSize: 14 }}>
                View details in{" "}
                <span
                  style={{
                    cursor: "pointer",
                    color: "#1890FF",
                    textDecoration: "underline",
                  }}
                  onClick={() => {
                    window.scrollTo(0, 0);
                    dispatch(push(`/${robotName}/health`));
                  }}
                >
                  Health
                </span>
              </span>
            }
            dateRange={last24Hours}
          />
          <RobotStatsCard robotId={robotId} />
          <Divider />
        </>
      )}

      <RobotAnalyticsPerformance
        range={range}
        robotId={robotId}
        datePickerComponent={datePickerComponent}
      />

      <Divider />

      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: 8,
          paddingRight: 8,
        }}
      >
        <Typography.Title style={{ color: "#082145" }} level={2}>
          Activity
        </Typography.Title>

        {(!isCustomer || isEssential) && (
          <div className="weld-activity-download-buttons">
            <Button
              icon={<DownloadOutlined />}
              onClick={() => {
                setOpenModal(true);
              }}
            >
              PDF
            </Button>
            <Button
              icon={<DownloadOutlined />}
              onClick={() => {
                const range = dateValues
                  ? GetDateRange("range", dateValues)
                  : [lastWeek.format("YYYY-MM-DD"), today.format("YYYY-MM-DD")];

                // weldRun should be the same as PdfModal weldReportData
                const data = generateCsvReports(robotWeldRun, robotTimezone);
                const dataStr =
                  "data:text/plain;charset=utf-8," + encodeURIComponent(data);
                const a = document.createElement("a");
                a.setAttribute("href", dataStr);
                a.setAttribute(
                  "download",
                  `weld activity ${range[0]} - ${range[1]}.csv`
                );
                document.body.appendChild(a);
                a.click();
                a.remove();
              }}
            >
              Excel
            </Button>
          </div>
        )}
      </Row>
      <Row gutter={[4, 4]}>
        <Col span={24}>
          <Card
            size="small"
            type="inner"
            bordered={false}
            title="Weld History"
            className="WeldHistory_Card"
          >
            <RobotWeldActivity weldRun={robotWeldRun} isMetric={isMetric} />
            {(!isCustomer || isEssential) && robotWeldRun?.length && (
              <PdfModal
                weldRun={robotWeldRun}
                openModal={openModal}
                robotName={robotName}
                type={"range"}
                dateValues={dateValues}
                timezone={robotTimezone}
                onModalClose={() => {
                  setOpenModal(false);
                }}
              />
            )}
          </Card>
        </Col>
      </Row>
      {!isCustomer && <RobotCSWrike robotId={robotId} />}
    </div>
  );
}

export default RobotAnalytics;
