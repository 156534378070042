import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { normalizeRobotId } from "./utils";
import { readRobotTimezone } from "../features/robot/robotSlice";

export default function useRobotTimezone<T extends boolean>(
  fallback: T,
): T extends true ? string : string | null {
  const dispatch = useDispatch();
  const { robotName } = useParams();
  const robotId: number | null =
    robotName == null ? null : normalizeRobotId(robotName);
  const robotTimezone: string | null = useSelector((state: any) => {
    if (robotId) {
      const robotTimezoneLookup = state.robot.timezone[robotId];
      if (robotTimezoneLookup) {
        return robotTimezoneLookup;
      }
    }
    return null;
  });

  const timezoneWithFallback: string =
    robotTimezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    if (robotId && !robotTimezone) {
      dispatch(readRobotTimezone({ id: robotId }));
    }
  }, [dispatch, robotId, robotTimezone]);

  return fallback ? timezoneWithFallback : robotTimezone;
}
