import { Card, Col, Divider, Row, Typography } from "antd";
import RobotDetail from "../RobotDetail";
import RobotScheduledEvents from "../RobotScheduledEvents";
import TicketsList from "../../wrike/TicketsList";
import TicketsStatistics from "../../wrike/TicketsStatistics";
import React, { useEffect } from "react";
import { getWrikeDataList } from "../../../libs/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  getCustomStatuses,
  getTickets,
  getUpcomingEvents,
} from "../../wrike/wrikeSlice";
import {
  CUSTOMER_MAINTENANCE_WRIKE_ID,
  CUSTOMER_SUPPORT_WRIKE_ID,
  WRIKE_TASK_PARAMS,
} from "../../../libs/constants";

interface Props {
  robotId: number;
}

export default function RobotCSWrike(props: Props) {
  const { robotId } = props;
  const dispatch = useDispatch();
  // @ts-ignore
  const tickets = useSelector((state) => state.wrike.tasks ?? []);
  // @ts-ignore
  const events = useSelector((state) => state.wrike.events);

  const filteredWrike = getWrikeDataList(tickets).filter(
    (ticket) => robotId === ticket.unit
  );

  useEffect(() => {
    dispatch(
      getTickets(
        `folders/${CUSTOMER_SUPPORT_WRIKE_ID}/tasks`,
        WRIKE_TASK_PARAMS
      )
    );
    dispatch(
      getUpcomingEvents(
        `folders/${CUSTOMER_MAINTENANCE_WRIKE_ID}/tasks`,
        WRIKE_TASK_PARAMS
      )
    );
    dispatch(getCustomStatuses());
  }, [dispatch]);

  return (
    <>
      <Divider />
      <Typography.Title style={{ color: "#082145" }} level={2}>
        CS Request and Maintenance
      </Typography.Title>
      <Row gutter={[16, 16]} style={{ padding: 8 }}>
        <Col span={12}>
          <Card
            size="small"
            type="inner"
            bordered={false}
            title="Detail Information"
            className="support-robot-detail"
          >
            <RobotDetail robotId={robotId} />
          </Card>
        </Col>
        <Col span={12}>
          <Card
            size="small"
            type="inner"
            bordered={false}
            title="Preventative Maintenance Checklist"
            style={{ height: "100%" }}
          >
            <RobotScheduledEvents
              events={events}
              style={{ height: 272, overflowY: "scroll", paddingTop: 10 }}
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ padding: 8 }}>
        <Col span={16}>
          <Card
            size="small"
            type="inner"
            bordered={false}
            title="Wrike Tasks List"
            className="support-robot-wrike-tickets"
          >
            <TicketsList tasks={filteredWrike} />
          </Card>
        </Col>
        <Col span={8}>
          <Card
            size="small"
            type="inner"
            bordered={false}
            title="Wrike Task Statistics"
            className="support-robot-wrike-stats"
          >
            <TicketsStatistics tasks={{ data: filteredWrike }} />
          </Card>
        </Col>
      </Row>
    </>
  );
}
