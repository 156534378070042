import { convertInchesTomm } from "../../../libs/utils";
import { Button, Modal } from "antd";
import { PDFDownload, PDFPreview } from "../../reports/GeneratePDFReports";
import React from "react";
import moment from "moment";

const HOURS_IN_SHIFT = 8;

interface Props {
  openModal: boolean;
  robotName: string;
  onModalClose: (boolean) => void;
  dateValues: [moment.Moment, moment.Moment];
  weldRun;
  type: "date" | "week" | "month" | "quarter" | "year" | "range";
  timezone: string;
}

export function PdfModal(props: Props) {
  const {
    openModal,
    robotName,
    onModalClose,
    dateValues,
    weldRun,
    type,
    timezone,
  } = props;

  const setOpenModal = (value) => {
    return onModalClose(value);
  };

  const isMetric =
    weldRun && weldRun[0] && weldRun[0].units_system === "metric";

  // weldRun should be the same as generateCsvReports 's weldRun data
  const weldReportData = weldRun
    .map((item) => {
      const idx = weldRun.findIndex((i) => i === item);
      const result = {
        ...item,
        weld_inches: isMetric
          ? convertInchesTomm(item.weld_inches).toFixed(0)
          : item.weld_inches,
        weld_fdi: item.weld_fdi,
        weld_di: item.weld_di,
      };

      if (
        idx < weldRun.length - 1 &&
        moment(
          moment(weldRun[idx + 1].arc_on_utc).utc(true),
          "YYYY-MM-DD",
        ).diff(
          moment(moment(item.arc_on_utc).utc(true), "YYYY-MM-DD"),
          "hour",
        ) >= -HOURS_IN_SHIFT
      ) {
        result.duration = moment.duration(
          moment(item.arc_on_utc).diff(moment(weldRun[idx + 1].arc_off_utc)),
        );
      }
      return result;
    })
    .reverse();

  return (
    <Modal
      style={{ width: "80vw" }}
      open={openModal}
      width={window.innerWidth / 1.4}
      footer={[
        <PDFDownload
          title="Operations Report"
          robotName={robotName}
          weldRun={weldReportData}
          type={type}
          date={dateValues}
        ></PDFDownload>,
        <Button
          style={{ marginLeft: 8 }}
          key="submit"
          type="primary"
          onClick={() => setOpenModal(false)}
        >
          Close
        </Button>,
      ]}
      onCancel={() => {
        setOpenModal(false);
      }}
      onOk={() => {
        setOpenModal(false);
      }}
    >
      <PDFPreview
        title="Operations Report"
        robotName={robotName}
        weldRun={weldReportData}
        type={type}
        date={dateValues}
        timezone={timezone}
      />
    </Modal>
  );
}
