import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { createLogger } from "redux-logger";

import rootReducer from "./rootReducer";

export const history = createBrowserHistory();

export default function configureAppStore() {
  const middleware = [...getDefaultMiddleware(), routerMiddleware(history)];

  if (process.env.NODE_ENV === "development") {
    middleware.push(createLogger());
  }

  return configureStore({
    reducer: rootReducer(history),
    middleware,
    // preloadedState,
  });
}

export type RootState = ReturnType<ReturnType<typeof rootReducer>>;
export type AppDispatch = ReturnType<typeof configureAppStore>["dispatch"];